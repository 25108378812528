/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { createRouter, createWebHashHistory, NavigationGuardNext, RouteLocationNormalized, Router, RouteRecordRaw } from 'vue-router';
import { Component } from "vue";
import { CdesContext } from "cdes-vue/core/CdesContext";
import { MainMenuSection } from "cdes-vue/layout/main/MainMenu";
import { ContextLevel } from 'cdes-vue/voc/ctx/ContextLevel';
import Iframe from "cdes-vue/util/Iframe.vue";
import ReviewTaskPage from "cdes-vue/review/task/ReviewTaskPage.vue";
import ReviewWidget from "cdes-vue/review/review/ReviewWidget.vue";
import ProjectParticipationPage from "cdes-vue/project/participation/ProjectParticipationPage.vue";
import SubProjectPage from "cdes-vue/subProject/SubProjectPage.vue";
import PlotTaskPage from "cdes-vue/plot/task/PlotTaskPage.vue";
import PlotOrderTemplatePage from "cdes-vue/plot/template/PlotOrderTemplatePage.vue";
import PlotOrderTemplateEditWidget from "cdes-vue/plot/template/PlotOrderTemplateEditWidget.vue";
import OriginalDocumentOrderPage from "cdes-vue/original/document/order/OriginalDocumentOrderPage.vue";
import ProvideOriginalPage from 'cdes-vue/original/document/ProvideOriginalPage.vue';
import ProjectParticipationEditWidget from "cdes-vue/project/participation/ProjectParticipationEditWidget.vue";
import SubProjectEditWidget from "cdes-vue/project/subProject/SubProjectEditWidget.vue";
import ProjectPage from "cdes-vue/project/project/ProjectPage.vue";
import ProjectEditWidget from "cdes-vue/project/project/ProjectEditWidget.vue";
import UploadFilePage from "cdes-vue/review/upload/UploadFilePage.vue";
import OrderAddressEditWidget from 'cdes-vue/plot/template/OrderAddressEditWidget.vue';
import InstantEmailPage from 'cdes-vue/email/InstantEmailPage.vue';
import LabelPage from 'cdes-vue/review/label/LabelPage.vue';
import LabelContentEditWidget from 'cdes-vue/review/label/LabelContentEditWidget.vue';
import LabelEditWidget from 'cdes-vue/review/label/LabelEditWidget.vue';
import LabelContentDocumentTypePage from 'cdes-vue/review/label/LabelContentDocumentTypePage.vue';
import { ErrorHelper } from 'cdes-vue/util/ErrorHelper';

import PlanPersonIframe from "cdes-vue/util/dojoIFrame/PlanPersonIframe.vue";
import PlanOrgenIframe from "cdes-vue/util/dojoIFrame/PlanOrgenIframe.vue";
import PlanArgenIframe from "cdes-vue/util/dojoIFrame/PlanArgenIframe.vue";
import BuekPersonIframe from "cdes-vue/util/dojoIFrame/BuekPersonIframe.vue";
import BuekOrgenIframe from "cdes-vue/util/dojoIFrame/BuekOrgenIframe.vue";
import BuekArgenIframe from "cdes-vue/util/dojoIFrame/BuekArgenIframe.vue";
import AdminPersonIframe from "cdes-vue/util/dojoIFrame/AdminPersonIframe.vue";
import AdminOrgenIframe from "cdes-vue/util/dojoIFrame/AdminOrgenIframe.vue";
import AdminArgenIframe from "cdes-vue/util/dojoIFrame/AdminArgenIframe.vue";
import AdminJobListIframe from "cdes-vue/util/dojoIFrame/AdminJobListIframe.vue";

import ProfileIFrame from "cdes-vue/util/dojoIFrame/ProfileIFrame.vue";
import AccessEditIFrame from "cdes-vue/util/dojoIFrame/AccessEditIFrame.vue";

import RolesIframe from "cdes-vue/util/dojoIFrame/RolesIframe.vue";
import RoleEditIframe from "cdes-vue/util/dojoIFrame/RoleEditIframe.vue";
import MasterDataListIFrame from "cdes-vue/util/dojoIFrame/MasterDataListIFrame.vue";

import PlanDeliverIframe from "cdes-vue/util/dojoIFrame/PlanDeliverIframe.vue";
import DocumentListIframe from "cdes-vue/util/dojoIFrame/DocumentListIframe.vue";
import PlotOrderIframe from "cdes-vue/util/dojoIFrame/PlotOrderIframe.vue";

import BuekTaskIframe from "cdes-vue/util/dojoIFrame/BuekTaskIframe.vue";
import BuekNotIframe from "cdes-vue/util/dojoIFrame/BuekNotIframe.vue";
import BuekOrderIframe from "cdes-vue/util/dojoIFrame/BuekOrderIframe.vue";
import BuekReportIframe from "cdes-vue/util/dojoIFrame/BuekReportIframe.vue";
import BuekProjectIframe from "cdes-vue/util/dojoIFrame/BuekProjectIframe.vue";
import BuekTemplateIframe from "cdes-vue/util/dojoIFrame/BuekTemplateIframe.vue";

import CertIframe from 'cdes-vue/util/dojoIFrame/CertIframe.vue';
import ManualIframe from 'cdes-vue/util/dojoIFrame/ManualIframe.vue';
import AboutIframe from 'cdes-vue/util/dojoIFrame/AboutIframe.vue';
import ObjectShowIFrame from 'cdes-vue/util/dojoIFrame/ObjectShowIFrame.vue';
import ObjectListIFrame from 'cdes-vue/util/dojoIFrame/ObjectListIFrame.vue';
import NetworkListIFrame from 'cdes-vue/util/dojoIFrame/NetworkListIFrame.vue';
import InstancesListIFrame from 'cdes-vue/util/dojoIFrame/InstancesListIFrame.vue';
import ProjectEditIframe from 'cdes-vue/util/dojoIFrame/ProjectEditIframe.vue';

function assertSingular<T>(arr: T | T[], err: string = "Only one parameter is allowed."): T {
    if (Array.isArray(arr)) {
        if (arr.length === 1) {
            return arr[0];
        } else {
            throw new Error(err);
        }
    } else {
        return arr;
    }
}

function assertOptionalSingular<T>(arr: T | T[], err: string = "Only one or zero of parameter is allowed."): T {
    if (Array.isArray(arr)) {
        if (arr.length === 1) {
            return arr[0];
        } else if (arr.length === 0) {
            return undefined;
        } else {
            throw new Error(err);
        }
    } else {
        return arr;
    }
}


export class RouterFactory {
    public static constructRouter(context : CdesContext) : Router {
        let REVIEW = MainMenuSection.REVIEW;
        let BUEK = MainMenuSection.BUEK;
        let ADMIN = MainMenuSection.ADMIN;
        let HELP = MainMenuSection.HELP;
        let USER = MainMenuSection.USER;

        const routes = [
            {
                path : "/",
                name : "home",
                redirect : "/plan/aufgaben"
            },

            // ============== Planprüfung =================
            // Aufgaben
            ...RouterFactory.getVueRefs(context, REVIEW, "aufgaben", ReviewTaskPage, false, ContextLevel.NONE, [{
                name: "uploadFile",
                path: "/uploadFile",
                component: UploadFilePage,
                props: route => {
                    const documentId = assertOptionalSingular(route.query.documentId);
                    const documentVersionId = assertOptionalSingular(route.query.documentVersionId);
                    const taskId = assertOptionalSingular(route.query.taskId);

                    return {
                        documentId: documentId != null ? Number.parseInt(documentId) : null,
                        taskId: taskId != null ? Number.parseInt(taskId) : null,
                        documentVersionId: documentVersionId != null ? Number.parseInt(documentVersionId) : null,
                    };
                },
            }, {
                name: "reviewProtocol",
                path: "/reviewProtocol",
                component: Iframe,
                props: route => ({
                    src: context.getDojoPageUrl("reviewProtocol", {
                        documentVersionId: route.query.documentVersionId,
                    }),
                }),
            }, {
                name: "reviewDetails",
                path: "/reviewDetails",
                component: Iframe,
                props: route => {
                    const isSurveillanceTabStr = assertSingular(route.query.isSurveillanceTab);
                    return {
                        src: context.getTapestryRequestUrl("TaskDetailsService", [
                            assertSingular(route.query.taskId),
                            assertSingular(route.query.projectId),
                            isSurveillanceTabStr === "true" ? "T" : "F",
                        ]),
                    };
                },
            }, {
                name: "review",
                path: "/review",
                component: ReviewWidget,
                props: (route) => {
                    return {
                        documentVersionId: Number.parseInt(assertSingular(route.query.documentVersionId)),
                        taskId: Number.parseInt(assertSingular(route.query.taskId)),
                        overriding: false,
                        taskStatus: route.query.taskStatus
                    };
                },
            }, {
                name: "reviewOverride",
                path: "/review",
                component: ReviewWidget,
                props: (route) => {
                    return {
                        documentVersionId: Number.parseInt(assertSingular(route.query.documentVersionId)),
                        taskId: Number.parseInt(assertSingular(route.query.taskId)),
                        overriding: true
                    };
                },
            }, {
                name: "plotOrderAcceptanceTask",
                path: "/plot/lieferbestaetigung",
                component: PlotTaskPage,
                props: (route) => {
                    return {
                        mode : "PlotOrderAcceptanceTask",
                        taskId: Number.parseInt(assertSingular(route.query.taskId)),
                        plotOrderId : Number.parseInt(assertSingular(route.query.plotOrderId)),
                    };
                },
            }, {
                name: "reviewCyclePlotTask",
                path: "/plot/drucken",
                component: PlotTaskPage,
                props: (route) => {
                    return {
                        mode : "ReviewCyclePlotTask",
                        taskId: Number.parseInt(assertSingular(route.query.taskId)),
                    };
                },
            }]),
            // Pläne / Dokumente
            RouterFactory.getDojoRef(context, REVIEW, "verzeichnis", "documentList", false, ContextLevel.SUB_PROJECT, DocumentListIframe),
            RouterFactory.getDojoRef(context, REVIEW, "liefern", "planDeliverCatalogue", false, ContextLevel.SUB_PROJECT, PlanDeliverIframe),
            RouterFactory.getTapestryRef(context, REVIEW, "pruefstatus", "reviewCycleStatusOverview", "Review", 1,
                                         "ReviewCycleStatusOverview", false, ContextLevel.SUB_PROJECT),
            ...RouterFactory.getVueRefs(context, REVIEW, "originale", OriginalDocumentOrderPage, false, ContextLevel.SUB_PROJECT, [{
                name: "requestOriginal",
                path: "/requestOriginal",
                component: ProvideOriginalPage,
                props: (route) => {
                    return {
                        originalDocumentOrderId: route.query.orderId,
                        taskId : route.query.taskId
                    }
                }
            }]),
            RouterFactory.getVueRef(context, REVIEW, "requestOriginal", ProvideOriginalPage, false, ContextLevel.SUB_PROJECT,),
            RouterFactory.getDojoRef(context, REVIEW, "plotauftr", "plotOrders", false, ContextLevel.PROJECT, PlotOrderIframe),

            // Projekt
            RouterFactory.getTapestryRef(context, REVIEW, "objekteOld", "objectListsOverview", "Review", 4, "ObjectListOverview", false, ContextLevel.SUB_PROJECT),

            {
                name: "bestandObj",
                path: "/adminAsBuiltSubProject/:id",
                component: Iframe,
                props: (route) => {
                    return {
                        addUpperCloseButton : true,
                        class : "cdes-page",
                        src: context.getTapestryRequestUrl("SecureDirectService/1/ProjectSectionOverview/administrateAsBuiltSubProject/$SecureDirectLink$3", [
                            "SsubProject",
                            "SSubProjectTakeOverWizard",
                            assertSingular(route.params.id)
                        ]),
                    };
                },
                meta: {
                    //fixedContext: true,
                }
            },

            {
                name: "bestandDoc",
                path: "/adminAsBuiltSubProject/:id",
                component: Iframe,
                props: (route) => {
                    return {
                        addUpperCloseButton : true,
                        class : "cdes-page",
                        src: context.getTapestryRequestUrl("SecureDirectService/1/DocumentSectionOverview/administrateAsBuiltDocuments/$SecureDirectLink$3", [
                            "SsubProject",
                            "SAsBuiltEditor",
                            assertSingular(route.params.id)
                        ]),
                    };
                },
                meta: {
                    //fixedContext: true,
                }
            },


            RouterFactory.getTapestryRef(context, REVIEW, "bestandObj", "administrateAsBuiltSubProject", "Project", 1, "SubProjectTakeOverWizard", false, ContextLevel.SUB_PROJECT),
            RouterFactory.getTapestryRef(context, REVIEW, "bestandDoc", "administrateAsBuiltDocuments", "Document", 1, "AsBuiltEditor", false, ContextLevel.SUB_PROJECT),
            RouterFactory.getTapestryRef(context, REVIEW, "prjpruefoptionen", "projectSectionOverview", "Project", 0, "ProjectRealmsOverview", false, ContextLevel.PROJECT),

            RouterFactory.getTapestryRef(context, REVIEW, "pruefblaetter", "ReviewCycleInstancesOverview", "Review", 4, "ReviewCycleInstancesOverview", false, ContextLevel.SUB_PROJECT),
            ...RouterFactory.getVueRefs(context, REVIEW, "plotvorlagen", PlotOrderTemplatePage, false, ContextLevel.PROJECT, [
                RouterFactory.getVueRef1Q("plotOrderTemplateNew", "/neu", PlotOrderTemplateEditWidget, "projectId"),
                RouterFactory.getVueRef1P("plotOrderTemplateEdit", "/:id/bearbeiten", PlotOrderTemplateEditWidget,
                                          "plotOrderTemplateId", "id"),
                RouterFactory.getVueRef1Q("orderAddressNew", "/addresse/neu", OrderAddressEditWidget, "networkId"),
                RouterFactory.getVueRef1P("orderAddressEdit", "/addresse/:id/bearbeiten", OrderAddressEditWidget,
                                          "orderAddressId", "id")
            ]),

            ...RouterFactory.getVueRefs(context, REVIEW, "teilnehmer", ProjectParticipationPage, false, ContextLevel.PROJECT, [{
                name: "projectParticipationNew",
                path: "/new",
                component: ProjectParticipationEditWidget,
                props: route => {
                    return {
                        searchModel : {
                            newForOrgMode : 1,
                            projectId : route.query.projectId
                        }
                    }
                }
            }, {
                name: "projectParticipationEdit",
                path: "/edit",
                component: ProjectParticipationEditWidget,
                // @ts-ignore
                props: (route) => {
                    return {
                        searchModel: {
                            projectId: route.query.projectId,
                            organisationId: route.query.organisationId,
                            cdesRoleId: route.query.cdesRoleId,
                            subProjectId: route.query.subProjectId,
                            mainParticipantPersonId: route.query.mainParticipantPersonId,
                            deputyParticipantPersonId: route.query.deputyParticipantPersonId,
                        },
                    };
                },
            }, {
                name: "personShow",
                path: "/person/:id",
                component: Iframe,
                props: (route) => {
                    return {
                        class : "cdes-page",
                        addUpperCloseButton : true,
                        src: context.getDojoPageUrl("personEdit", {
                            mode: "Show",
                            id: Number.parseInt(assertSingular(route.params.id)),
                            omitCloseButton : true
                        }),
                    };
                },
            }]),
            //RouterFactory.getTapestryRef(context, REVIEW, "projekte", "projectsOverview", "Administration",6,
             //                            "ContextProjectsOverview", false, ContextLevel.NETWORK),
            ...RouterFactory.getVueRefs(context, REVIEW, "projekte", ProjectPage, false, ContextLevel.NONE, [{
                name: "newSubProject",
                path: "/subProject/new",
                component: SubProjectEditWidget,
                props: (route) => {
                    if (Array.isArray(route.query.projectId)) {
                        throw new Error("projectId specified multiple times.");
                    }

                    return {
                        projectId: Number.parseInt(route.query.projectId),
                    };
                },
            }, {
                name: "editSubProject",
                path: "/subProject/:id/edit",
                component: SubProjectEditWidget,
                props: (route) => {
                    return {
                        subProjectId: route.params.id,
                    };
                },
            }, {
                name: "editProject",
                path: "/project/:id/edit",
                component: ProjectEditWidget,
                props: route => ({
                    projectId: Number.parseInt(assertSingular(route.params.id, "Only one id parameter is allowed")),
                }),
            }, {
                name: "newProject",
                path: "/project/new",
                component: ProjectEditWidget,
                props: route => ({
                    networkId: Number.parseInt(assertSingular(route.query.networkId, "Only one networkId parameter is allowed.")),
                }),
            }, {
                name: "newProjectBs",
                path: "/neu",
                component: ProjectEditIframe,
                props : (route) => {
                    return {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/plan/projekte/neu")
                    };
                }
            }, {
                name: "sendEmail",
                path: "/sendEmail",
                component: InstantEmailPage,
                props: (route) => {
                    return {
                        participationIds : route.query.participationIds,
                        organisationPersonId : route.query.organisationPersonId,
                        targetProjectId : route.query.targetProjectId
                    };
                }
            }]),
            // Netzwerk
            RouterFactory.getDojoRef(context, REVIEW, "planPersonen", "personList", false, ContextLevel.NONE, PlanPersonIframe),
            RouterFactory.getDojoRef(context, REVIEW, "planOrgen", "organisationList", false, ContextLevel.NONE, PlanOrgenIframe),
            RouterFactory.getDojoRef(context, REVIEW, "planArgen", "consortiumList", false, ContextLevel.NONE, PlanArgenIframe),
            RouterFactory.getDojoRef(context, REVIEW, "zert", "certificates", false, ContextLevel.NONE, CertIframe),
            RouterFactory.getTapestryRef(context, REVIEW, "stammdaten_alt", "documentTypesOverview", "Administration", 5, "NetworkMasterDataAdministration", false, ContextLevel.NETWORK),
            ...RouterFactory.getVueRefs(context, REVIEW, "musterplan", LabelPage, false, ContextLevel.NETWORK, [{
                name: "labelEdit",
                path: "/edit",
                component: LabelEditWidget,
                // @ts-ignore
                props: (route) => {
                    return {
                        labelId: route.query.labelId
                    };
                },
            }, {
                name: "labelContentEdit",
                path: "/contentEdit",
                component: LabelContentEditWidget,
                // @ts-ignore
                props: (route) => {
                    return {
                        labelContentId : parseInt(typeof route.query.labelContentId == "string"
                            ? route.query.labelContentId : null),
                        labelId : route.query.labelId
                    };
                },
            }, {
                name : "labelContentDocumentTypePage",
                path : "/plantypen",
                component : LabelContentDocumentTypePage,
                // @ts-ignore
                props : (route) => {
                    return {
                        // @ts-ignore
                        labelContentId : route.query.labelContentId
                    };
                }
            }]),
            RouterFactory.getBsRef(context, ADMIN, "netzwerke/liste",
                                   "admin/netzwerke/liste", false, ContextLevel.NONE,
                                   NetworkListIFrame),
            RouterFactory.getBsRef(context, REVIEW, "objekte/liste",
                                   "plan/objekte/liste", false, ContextLevel.SUB_PROJECT,
                                   ObjectListIFrame),
            ...RouterFactory.getBsRefs(context, REVIEW, "objekte", "/plan/objekte", false, ContextLevel.SUB_PROJECT,
                                       ObjectShowIFrame, [
                                           {
                name: "objectPlannerReleaseShowDocumentVersion",
                path: "/anzeigen/plan/:documentVersionId",
                component: ObjectShowIFrame,
                // @ts-ignore
                props: (route) => {
                    let retObj = {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/plan/objekte/anzeigen/plan/" + route.params.documentVersionId, {
                            fromPage : route.params.fromPage
                        })
                    };
                    return retObj;
                }}, {
                name: "instancesList",
                path: "/pruefblaetter/liste",
                component: InstancesListIFrame,
                // @ts-ignore
                props: (route) => {
                    let retObj = {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/plan/objekte/pruefblaetter/liste", {
                            fromPage : route.params.fromPage
                        })
                    };
                    return retObj;
                }}, {
                name: "objectPlannerReleaseShowDocument",
                path: "/anzeigen/plk/:documentId",
                component: ObjectShowIFrame,
                // @ts-ignore
                props: (route) => {
                    let retObj = {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/plan/objekte/anzeigen/plk/" + route.params.documentId, {
                            fromPage : route.params.fromPage
                        })
                    };
                    return retObj;
                }}, {
                name: "objectPlannerReleaseShow",
                path: "/:objectId/:version/anzeigen",
                component: ObjectShowIFrame,
                // @ts-ignore
                props: (route) => {
                    let retObj = {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/plan/objekte/" + route.params.objectId + "/" + route.params.version + "/anzeigen", {
                            objblatt : route.params.objectPlannerReleaseId,
                            fromPage : route.params.fromPage
                        })
                    };
                    return retObj;
                }
            }]),
            // ============== BÜK  =================
            // Aufgaben
            RouterFactory.getDojoRef(context, BUEK, "buekAufgaben", "planningNotificationTaskList", true, ContextLevel.NONE, BuekTaskIframe),
            // Bescheide / Vorgaben
            RouterFactory.getDojoRef(context, BUEK, "bescheide", "planningNotificationList", true, ContextLevel.NETWORK, BuekNotIframe),
            RouterFactory.getDojoRef(context, BUEK, "vorgaben", "planningNotificationOrderList", true, ContextLevel.NETWORK, BuekOrderIframe),
            RouterFactory.getDojoRef(context, BUEK, "reporting", "planningNotificationReport", true, ContextLevel.NETWORK, BuekReportIframe),
            // Projekt
            RouterFactory.getDojoRef(context, BUEK, "buekProjekte", "planningNotificationProjectList", true, ContextLevel.NETWORK, BuekProjectIframe),
//            RouterFactory.getTapestryRef(context, BUEK, "teilnehmer", "projectParticipationsOverview", "Review", 5, "ProjectParticipationAdministration", true, ContextLevel.PROJECT),
            ...RouterFactory.getVueRefs(context, BUEK, "teilnehmerBuek", ProjectParticipationPage, true, ContextLevel.PROJECT, [{
                name: "projectParticipationNewBuek",
                path: "/new",
                component: ProjectParticipationEditWidget,
                props: route => {
                    return {
                        searchModel : {
                            newForOrgMode : 1,
                            projectId : route.query.projectId
                        }
                    }
                }
            }, {
                name: "projectParticipationEditBuek",
                path: "/edit",
                component: ProjectParticipationEditWidget,
                // @ts-ignore
                props: (route) => {
                    return {
                        searchModel: {
                            projectId: route.query.projectId,
                            organisationId: route.query.organisationId,
                            cdesRoleId: route.query.cdesRoleId,
                            subProjectId: route.query.subProjectId,
                            mainParticipantPersonId: route.query.mainParticipantPersonId,
                            deputyParticipantPersonId: route.query.deputyParticipantPersonId,
                        },
                    };
                },
            }, {
                name: "personShowBuek",
                path: "/person/:id",
                component: Iframe,
                props: (route) => {
                    return {
                        class : "cdes-page",
                        addUpperCloseButton : true,
                        src: context.getDojoPageUrl("personEdit", {
                            mode: "Show",
                            id: Number.parseInt(assertSingular(route.params.id)),
                            omitCloseButton : true
                        }),
                    };
                },
            }, {
                name: "sendEmailBuek",
                path: "/sendEmail",
                component: InstantEmailPage,
                props: (route) => {
                    return {
                        participationIds : route.query.participationIds,
                        organisationPersonId : route.query.organisationPersonId,
                        targetProjectId : route.query.targetProjectId
                    };
                }
            }]),
            // Netzwerk
            RouterFactory.getDojoRef(context, BUEK, "buekPersonen", "personList", true, ContextLevel.NONE, BuekPersonIframe),
            RouterFactory.getDojoRef(context, BUEK, "buekOrgen", "organisationList", true, ContextLevel.NONE, BuekOrgenIframe),
            RouterFactory.getDojoRef(context, BUEK, "buekArgen", "consortiumList", true, ContextLevel.NONE, BuekArgenIframe),

            // ============== Administration ==============
            // Projekt
            RouterFactory.getVueRef(context, REVIEW, "prjstatus", SubProjectPage, false, ContextLevel.PROJECT),
//            RouterFactory.getTapestryRef(context, ADMIN, "prjpruefoptionen", "projectSectionOverview", "Project", 0, "ProjectRealmsOverview", false, ContextLevel.PROJECT),

            //RouterFactory.getVueRef(context, ADMIN, "plotvorlagen", PlotOrderTemplatePage, false, ContextLevel.PROJECT),
            /*
            ...RouterFactory.getVueRefs(context, ADMIN, "plotvorlagen", PlotOrderTemplatePage, false, ContextLevel.PROJECT, [
                RouterFactory.getVueRef1Q("plotOrderTemplateNew", "/neu", PlotOrderTemplateEditWidget, "projectId"),
                RouterFactory.getVueRef1P("plotOrderTemplateEdit", "/:id/bearbeiten", PlotOrderTemplateEditWidget,
                                          "plotOrderTemplateId", "id"),
                RouterFactory.getVueRef1Q("orderAddressNew", "/addresse/neu", OrderAddressEditWidget, "networkId"),
                RouterFactory.getVueRef1P("orderAddressEdit", "/addresse/:id/bearbeiten", OrderAddressEditWidget,
                                          "orderAddressId", "id")
            ]),*/

            // Netzwerk
            //RouterFactory.getTapestryRef(context, ADMIN, "projekte_alt", "projectsOverview", "Administration",6, "ContextProjectsOverview", false, ContextLevel.NETWORK),
             ...RouterFactory.getVueRefs(context, REVIEW, "projekte", ProjectPage, false, ContextLevel.NONE, [{
                name: "newSubProject",
                path: "/subProject/new",
                component: SubProjectEditWidget,
                props: (route) => {
                    if (Array.isArray(route.query.projectId)) {
                        throw new Error("projectId specified multiple times.");
                    }

                    return {
                        projectId: Number.parseInt(route.query.projectId),
                    };
                },
            }, {
                name: "editSubProject",
                path: "/subProject/:id/edit",
                component: SubProjectEditWidget,
                props: (route) => {
                    return {
                        subProjectId: route.params.id,
                    };
                },
            }, {
                name: "editProjectAdmin",
                path: "/project/:id/edit",
                component: ProjectEditWidget,
                props: route => ({
                    projectId: Number.parseInt(assertSingular(route.params.id, "Only one id parameter is allowed")),
                }),
            }, {
                name: "newProject",
                path: "/project/new",
                component: ProjectEditWidget,
                props: route => ({
                    networkId: Number.parseInt(assertSingular(route.query.networkId, "Only one networkId parameter is allowed.")),
                }),
            }, {
                name: "sendEmail",
                path: "/sendEmail",
                component: InstantEmailPage,
                props: (route) => {
                    return {
                        participationIds : route.query.participationIds,
                        organisationPersonId : route.query.organisationPersonId,
                        targetProjectId : route.query.targetProjectId
                    };
                }
            }]),
            RouterFactory.getDojoRef(context, ADMIN, "adminPersonen", "personList", false, ContextLevel.NONE, AdminPersonIframe),
            RouterFactory.getDojoRef(context, ADMIN, "adminOrgen", "organisationList", false, ContextLevel.NONE, AdminOrgenIframe),
            RouterFactory.getDojoRef(context, ADMIN, "adminArgen", "consortiumList", false, ContextLevel.NONE, AdminArgenIframe),
            RouterFactory.getDojoRef(context, ADMIN, "adminZert", "certificates", false, ContextLevel.NONE, CertIframe),
            ...RouterFactory.getBsRefs(context, ADMIN, "rollen", "/admin/rollen", false, ContextLevel.NETWORK, RolesIframe, [{
                name: "roleEdit",
                path: "/:id/bearbeiten",
                component: RoleEditIframe,
                props : (route) => {
                    return {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/admin/rollen/" + route.params.id + "/bearbeiten", {
                            mode : route.params.mode
                        })
                    };
                }
            }, {
                name: "roleNew",
                path: "/neu",
                component: RoleEditIframe,
                props : (route) => {
                    return {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/admin/rollen/neu")
                    };
                }
            }, {
                name: "roleActionEdit",
                path: "/rechte",
                component: RoleEditIframe,
                props : (route) => {
                    return {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/admin/rollen/rechte", {
                            initialRoleId : route.params.id,
                            mode : route.params.mode
                        })
                    };
                }
            }]),
            /*
            ...RouterFactory.getBsRefs(context, USER, "profil", "/user/profil", false, ContextLevel.NONE, ProfileIFrame, [{
                name: "accessEdit",
                path: "/zugang",
                component: AccessEditIFrame,
                props : (route) => {
                    return {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/user/profil/zugang", {
                            mode : route.params.mode
                        })
                    };
                }
            }, {
                name: "personalDataEdit",
                path: "/daten",
                component: ProfileIFrame,
                props : (route) => {
                    return {
                        class : "cdes-page",
                        src : context.getBsPageUrl("/user/profil/daten")
                    };
                }
            }]),*/
//            RouterFactory.getTapestryRef(context, ADMIN, "rollenAlt", "rolesOverview", "Administration", 4, "RoleAdministration", false, ContextLevel.NETWORK),

            RouterFactory.getBsRef(context, REVIEW, "stammdaten/liste",
                                   "admin/stammdaten/liste", false, ContextLevel.NETWORK,
                                   MasterDataListIFrame),

            RouterFactory.getBsRef(context, ADMIN, "stammdaten/liste",
                                   "admin/stammdaten/liste", false, ContextLevel.NETWORK,
                                   MasterDataListIFrame),

            RouterFactory.getTapestryRef(context, ADMIN, "stammdaten_alt", "documentTypesOverview", "Administration", 5, "NetworkMasterDataAdministration", false, ContextLevel.NETWORK),
            RouterFactory.getTapestryRef(context, ADMIN, "musterplan_alt", "labelsOverview", "Document", 5, "OrganisationLabelsOverview", false, ContextLevel.NETWORK),
            ...RouterFactory.getVueRefs(context, ADMIN, "musterplan", LabelPage, false, ContextLevel.NETWORK, [{
                name: "labelEditAdmin",
                path: "/edit",
                component: LabelEditWidget,
                // @ts-ignore
                props: (route) => {
                    return {
                        labelId: route.query.labelId
                    };
                },
            }, {
                name: "labelContentEditAdmin",
                path: "/contentEdit",
                component: LabelContentEditWidget,
                // @ts-ignore
                props: (route) => {
                    return {
                        labelContentId : parseInt(typeof route.query.labelContentId == "string"
                            ? route.query.labelContentId : null),
                        labelId : route.query.labelId
                    };
                },
            }, {
                name : "labelContentDocumentTypePageAdmin",
                path : "/plantypen",
                component : LabelContentDocumentTypePage,
                // @ts-ignore
                props : (route) => {
                    return {
                        // @ts-ignore
                        labelContentId : route.query.labelContentId
                    };
                }
            }]),

            RouterFactory.getDojoRef(context, ADMIN, "adminJobList", "jobList", false, ContextLevel.NETWORK, AdminJobListIframe),

            // Global
            RouterFactory.getTapestryRef(context, ADMIN, "prueflauf", "reviewCyclesGlobalOverview", "GlobalAdministration", 0, "ReviewCyclesOverview", false, ContextLevel.NONE),
            RouterFactory.getTapestryRef(context, ADMIN, "netzwerke", "networksGlobalOverview", "GlobalAdministration", 1, "NetworkAdministration", false, ContextLevel.NONE),
            RouterFactory.getTapestryRef(context, ADMIN, "globalestammdaten", "reviewCyclesGlobalOverview", "GlobalAdministration", 2, "MasterDataAdministration", false, ContextLevel.NONE),
            RouterFactory.getTapestryRef(context, ADMIN, "globalerollen", "rolesGlobalOverview", "GlobalAdministration", 3, "GlobalRoleAdministration", false, ContextLevel.NONE),
            RouterFactory.getTapestryRef(context, ADMIN, "ca", "manageRootCAs", "GlobalAdministration", 4, "RootCAAdministration", false, ContextLevel.NONE),
            // BÜK
            RouterFactory.getDojoRef(context, ADMIN, "einstellungen", "planningNotificationTemplateList", true, ContextLevel.NONE, BuekTemplateIframe),
            RouterFactory.getBsRef(context, USER, "profil/zugang", "user/profil/zugang", false, ContextLevel.NONE, AccessEditIFrame),
            RouterFactory.getBsRef(context, USER, "profil/daten", "user/profil/daten", false, ContextLevel.NONE, ProfileIFrame),
            // Sonstige

            // ======================= Hilfe ===================
            RouterFactory.getTapestryRef(context, HELP, "info", "editInfoMessages", "GlobalAdministration", 5, "InfoMessagesOverview", false, ContextLevel.NONE),
            RouterFactory.getTapestryRef(context, HELP, "emails", "networksGlobalOverview", "GlobalAdministration", 6, "EmailAdministration", false, ContextLevel.NONE),
//SecureDirectService/1/Top/networksGlobalOverview/$MenuGlobalAdministration.$MenuDirectLink$6&sp=SEmailAdministration
//SecureDirectService/1/Top/networksGlobalOverview/$MenuGlobalAdministration.$MenuDirectLink$6&serviceParam=SEmailAdministration&locale=de&ts=Admin
            RouterFactory.getTapestryRef(context, HELP, "statistik", "networksGlobalOverview", "GlobalAdministration", 7, "PlatformStatistics", false, ContextLevel.NETWORK),
            RouterFactory.getTapestryRef(context, HELP, "support", "showSupport", "Help", null, "ShowSupport", false, ContextLevel.NONE),
            RouterFactory.getManualRef(context, HELP, "manual", false, ContextLevel.NONE),
//            RouterFactory.getManualRef(context, HELP, "manual", "manual"),
//            RouterFactory.getDojoRef(context, HELP, "manual", "manual", false, ContextLevel.NONE, ManualIframe),
            RouterFactory.getDojoRef(context, HELP, "about", "about", false, ContextLevel.NONE, AboutIframe),

            // ===================== Benutzer-Menü oben rechts ==========================
            RouterFactory.getTapestryRef(context, USER, "einstellungen", "editPersonalData", null, 1, null, false, ContextLevel.NONE),

            {
                name: "showObjectPlanner",
                path: "/objectPlanner/:id",
                component: Iframe,
                props: (route) => {
                    return {
                        addUpperCloseButton : true,
                        class : "cdes-page",
                        src: context.getTapestryRequestUrl("external/ShowObject", [
                            assertSingular(route.query.objectId),
                            assertSingular(route.query.objectListId),
                            assertSingular(route.params.id),
                        ]),
                    };
                },
                meta: {
                    //fixedContext: true,
                }
            },

            {
                name: "showObjectPlannerWithoutClose",
                path: "/objectPlanner/:id",
                component: Iframe,
                props: (route) => {
                    return {
                        addUpperCloseButton : true,
                        class : "cdes-page",
                        src: context.getTapestryRequestUrl("external/ShowObject", [
                            assertSingular(route.query.objectId),
                            assertSingular(route.query.objectListId),
                            assertSingular(route.params.id),
                            1
                        ]),
                    };
                },
                meta: {
                    //fixedContext: true,
                }
            },

// http://localhost:8885/cdes/app?service=SecureDirectService/1/Top/editPersonalData/$MenuDirectLink$1&locale=de&ts=Admin
// http://localhost:8885/cdes/app?service=SecureDirectService%2F1%2FTop%2FeditPersonalData%24MenuDirectLink%241&locale=de&ts=Admin


//            http://localhost:8885/cdes/app?service=SecureDirectService/1/Top/networksGlobalOverview/$MenuGlobalAdministration.$MenuDirectLink$7&sp=SPlatformStatistics&locale=de&ts=Admin
//            http://localhost:8885/cdes/app?service=SecureDirectService/1/Top/editPersonalData/$MenuDirectLink$1&locale=de&ts=Admin

            // Compare https://next.router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
            { path : "/:pathMatch(.*)*", redirect : RouterFactory.getRouterPath(MainMenuSection.REVIEW, "verzeichnis") }

/*
            RouterFactory.getDojoRef(ADMIN, "/jobs", "jobList"),
            RouterFactory.getTapestryRef(ADMIN, "/review/options", "reviewCyclesGlobalOverview", "GlobalAdministration", null,
                                         "ReviewCyclePositionTypesOverview"),
*/






        ];

        let router : Router = createRouter({
            history : createWebHashHistory(),
            routes : routes
        });

        // We may not open Cdes pages before the initialization steps
        // triggered by CdesContext.initialize are finished.
        // Unfortunately, they cannot be triggered before app.mounted is
        // called, i.e. our only chance to postpone things if needed seems
        // to be here.
        router.beforeEach((to : RouteLocationNormalized, _from : RouteLocationNormalized, next : NavigationGuardNext) => {
            let initializePromise : Promise<unknown> = context.getInitializePromise();
            if (initializePromise != null) {
                initializePromise.then(() => {
                    next();
                }, err => {
                    ErrorHelper.processErrorWithoutI18n(err);
                });
            } else {
                next();
            }
        });

        router.beforeEach((to) => {
            if ("contextNetworkId" in to.query || "contextProjectId" in to.query || "contextSubProjectId" in to.query) {
                if ("contextNetworkId" in to.query) {
                    context.activeNetworkId = Number.parseInt(assertSingular(to.query.contextNetworkId));
                }
                if ("contextProjectId" in to.query) {
                    context.activeProjectId = Number.parseInt(assertSingular(to.query.contextProjectId));
                }
                if ("contextSubProjectId" in to.query) {
                    context.activeProjectId = Number.parseInt(assertSingular(to.query.contextSubProjectId));
                }

                return {
                    ...to,
                    query: {
                        ...Object.fromEntries(Object.entries(to.query)
                            .filter(([k, v]) => !["contextSubProjectId", "contextProjectId", "contextNetworkId"].includes(k))),
                    },
                    replace: true,
                    force: true,
                };
            } else {
                return undefined;
            }
        });

        return router;
    }

    private static getRouterPath(section : MainMenuSection, path : string) : string {
        if (section == null) {
            return "/" + path;
        } else {
            return "/" + section + "/" + path;
        }
    }

    private static getDojoRef(context : CdesContext, section : MainMenuSection, path : string, dojoPath : string, buek : boolean,
                              contextLevel : ContextLevel, component) : RouteRecordRaw {
        console.info("registering dojoPath [" + dojoPath + "], path [" + path + "]");
        console.info("... dojoPageUrl [" + context.getDojoPageUrl(dojoPath) + "]");
        console.info("... routerPath [" + RouterFactory.getRouterPath(section, path) + "]");
        return {
            name : path,
            path : RouterFactory.getRouterPath(section, path),
            // In fact, we need Iframe as component here.
            // The workaround around #479 (after hours of debugging)
            // was to create a separate Iframe component for each Dojo page.
            // Senseless, but seems to work...
            component : component,
            props : () => {
                console.info("Evaluating props, dojoPath [" + dojoPath + "]");
                return ({
                    class : "cdes-page",
                    src : context.getDojoPageUrl(dojoPath),
                });
            },
            meta: {
                section,
                buek,
                contextLevel,
            },
        };
    }

    private static getBsRef(context : CdesContext, section : MainMenuSection, path : string, bsPath : string, buek : boolean,
                              contextLevel : ContextLevel, component) : RouteRecordRaw {
        console.info("registering bsPath [" + bsPath + "], path [" + path + "]");
        console.info("... bsPageUrl [" + context.getBsPageUrl(bsPath) + "]");
        console.info("... routerPath [" + RouterFactory.getRouterPath(section, path) + "]");
        return {
            name : section + "/" + path,
            path : RouterFactory.getRouterPath(section, path),
            // In fact, we need Iframe as component here.
            // The workaround around #479 (after hours of debugging)
            // was to create a separate Iframe component for each Dojo page.
            // Senseless, but seems to work...
            component : component,
            props : () => {
                console.info("Evaluating props, bsPath [" + bsPath + "], bsPageUrl is [" + context.getBsPageUrl(bsPath) + "]");
                return ({
                    class : "cdes-page",
                    src : context.getBsPageUrl(bsPath),
                });
            },
            meta: {
                section,
                buek,
                contextLevel,
            },
        };
    }

    private static getBsRefs(context: CdesContext, section : MainMenuSection, path : string, bsPath : string,
                             buek : boolean, contextLevel : ContextLevel, component : Component,
                             virtualChildren: RouteRecordRaw[]) : RouteRecordRaw[] {

        console.info("registering bsPath [" + bsPath + "], path [" + path + "]");
        console.info("... bsPageUrl [" + context.getBsPageUrl(bsPath) + "]");
        console.info("... routerPath [" + RouterFactory.getRouterPath(section, path) + "]");
        let routePath : string = RouterFactory.getRouterPath(section, path);
        let meta = {
            section,
            buek,
            contextLevel,
        };
        return [{
            name : section + "/" + path,
            path : routePath,
            // In fact, we need Iframe as component here.
            // The workaround around #479 (after hours of debugging)
            // was to create a separate Iframe component for each Dojo page.
            // Senseless, but seems to work...
            component : component,
            props : () => {
                console.info("Evaluating props, bsPath [" + bsPath + "]");
                return ({
                    class : "cdes-page",
                    src : context.getBsPageUrl(bsPath),
                });
            },
            meta : meta
            // @ts-ignore
        }, ...virtualChildren.map(child => ({
            ...child,
            path: routePath + child.path,
            /*
            props : () => {
                let path : string = routePath + child.path;
                console.info("Evaluating props, bsPath [" + bsPath + "], path [" + path + "]");
                return ({
                    class : "cdes-page",
                    src : context.getBsPageUrl(path),
                });
            },*/
            meta: {
                ...meta,
                fixedContext: true,
                ...child.meta,
            },
        }))];


        /*
        const meta = {
            section,
            buek,
            contextLevel,
        };
        const routePath = RouterFactory.getRouterPath(section, path);
        console.info("Registering name [" + section + "/" + path + "]");
        return [{
            name : section + "/" + path,
            path: routePath,
            // In fact, we need Iframe as component here.
            // The workaround around #479 (after hours of debugging)
            // was to create a separate Iframe component for each Dojo page.
            // Senseless, but seems to work...
            component : component,
            props : () => {
                console.info("Evaluating props, bsPath [" + bsPath + "]");
                return ({
                    class : "cdes-page",
                    src : context.getBsPageUrl(bsPath),
                });
            },
            meta,
        }, ...virtualChildren.map(child => ({
            ...child,
            path: routePath + child.path,
            meta: {
                ...meta,
                fixedContext: true,
                ...child.meta,
            },
            }))];
            */
    }

    private static getTapestrySrc(context: CdesContext, action: string, tapestryIndex: number, tapestryMenu: string, serviceParam: string): string {
        // We assemble a service parameter like
        //   SecureDirectService/1/Top/manageRootCAs/$MenuGlobalAdministration.$MenuDirectLink$4
        // here.
        let service : string =
            "SecureDirectService/1/Top/" + action
            + (tapestryMenu != null ? "/$Menu" + tapestryMenu + "." : "/")
            + "$MenuDirectLink"
            + (tapestryIndex != null ? "$" + tapestryIndex.toString() : "");
        serviceParam = (serviceParam != null ? "S" + serviceParam : null);

        let url = context.getTapestryPageUrl(service, serviceParam);
        return url;
    }

    private static getTapestryRef(context: CdesContext, section : MainMenuSection, path : string, action : string,
                                  tapestryMenu : string, tapestryIndex : number,
                                  serviceParam : string, buek : boolean, contextLevel : ContextLevel) : RouteRecordRaw {
        return {
            path : RouterFactory.getRouterPath(section, path),
            component : Iframe,
            props : () => ({
                class : "cdes-page",
                src : this.getTapestrySrc(context, action, tapestryIndex, tapestryMenu, serviceParam)
            }),
            meta: {
                section,
                buek,
                contextLevel,
            },
        };
    }

    private static getManualRef(context: CdesContext, section : MainMenuSection, path : string,
                                buek : boolean, contextLevel : ContextLevel) : RouteRecordRaw {
        return {
            path : RouterFactory.getRouterPath(section, path),
            component : Iframe,
            props : () => ({
                class : "cdes-page",
                src : context.getManualUrl()
//                src : this.getTapestrySrc(context, action, tapestryIndex, tapestryMenu, serviceParam)
            }),
            meta: {
                section,
                buek,
                contextLevel,
            },
        };
    }
/*
    private static getManualRef(context : CdesContext, section : MainMenuSection, path : string, action : string) : RouteRecordRaw {
        return {
            name: "manual",
            path: "/manual",
            component: Iframe,
            props : () => ({
                class : "cdes-page",
                src : context.getManualUrl()
            }),
            meta: {
                section : section,
                buek : false,
                contextLevel : ContextLevel.NONE
            }
        };
    }
*/
    private static getVueRef(context: CdesContext, section : MainMenuSection, path : string, component : Component,
                             buek : boolean, contextLevel : ContextLevel) : RouteRecordRaw {
        return {
            path : RouterFactory.getRouterPath(section, path),
            component : component,
            meta: {
                section,
                buek,
                contextLevel,
            },
        };
    }

    // naming: 1Q = with one query parameter
    // assumptions: unique, and integer (both are the standard cases in our usecase, if we ever need
    //                                   something else we can implement it as separate case)
    private static getVueRef1Q(name : string, path : string, component : Component, queryName : string) {
        return {
            name : name,
            path : path,
            component : component,
            props: (route) => {
                if (Array.isArray(route.query[queryName])) {
                    throw new Error("Query parameter [" + queryName + "] specified multiple times.");
                }

                let retObject = new Object();
                retObject[queryName] = Number.parseInt(route.query[queryName]);
                return retObject;
            },
        }
    }

    private static getVueRef1P(name : string, path : string, component : Component, retParamName : string, routeParamName : string) {
        return {
            name : name,
            path : path,
            component : component,
            props: (route) => {
                let retObject = new Object();
                retObject[retParamName] = Number.parseInt(route.params[routeParamName]);
                return retObject;
            },
        }
    }

    private static getVueRef1P1Q(name : string, path : string, component : Component, retParamName : string, routeParamName : string,
                                 queryName : string) {
        return {
            name : name,
            path : path,
            component : component,
            props: (route) => {
                if (Array.isArray(route.query[queryName])) {
                    throw new Error("Query parameter [" + queryName + "] specified multiple times.");
                }

                let retObject = new Object();
                retObject[retParamName] = Number.parseInt(route.params[routeParamName]);
                retObject[queryName] = Number.parseInt(route.query[queryName]);
                return retObject;
            },
        }
    }

    // NOTE: vue-router has a nested route functionality wich also has child routes.
    // but if you have something similar to nested routes without the nesting this function is for you.
    private static getVueRefs(context: CdesContext, section : MainMenuSection, path : string, component : Component,
                              buek : boolean, contextLevel : ContextLevel, virtualChildren: RouteRecordRaw[]) : RouteRecordRaw[] {
        const meta = {
            section,
            buek,
            contextLevel,
        };
        const routePath = RouterFactory.getRouterPath(section, path);
        console.info("Registering name [" + section + "/" + path + "]");
        return [{
            name : section + "/" + path,
            path: routePath,
            component,
            meta,
        }, ...virtualChildren.map(child => ({
            ...child,
            path: routePath + child.path,
            meta: {
                ...meta,
                fixedContext: true,
				invisibleContext: true,
                ...child.meta,
            },
        }))];
    }
}
