import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-lg-4" }
const _hoisted_2 = { class: "row g-3" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md" }
const _hoisted_7 = { class: "col-lg-4" }
const _hoisted_8 = { class: "row g-3" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-md" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-md" }
const _hoisted_13 = { class: "col-lg-4" }
const _hoisted_14 = { class: "row g-3" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col-md" }
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "row g-3" }
const _hoisted_21 = { class: "col-auto" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "col-auto" }
const _hoisted_24 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_TreeSelect = _resolveComponent("TreeSelect")!

  return (_openBlock(), _createElementBlock("form", {
    class: "row g-3",
    onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (this.$emit('search', this.searchModel)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Label, {
              class: "col-md-10-em col-form-label text-md-end",
              for: _ctx.$id('type')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.type')), 1)
              ]),
              _: 1
            }, 8, ["for"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_CustomSelect, {
                disabled: this.disabled,
                id: _ctx.$id('type'),
                optional: "",
                optionalLabel: _ctx.$t('general.all'),
                options: this.getTypeOptions(),
                modelValue: this.originalTypeValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.originalTypeValue) = $event))
              }, null, 8, ["disabled", "id", "optionalLabel", "options", "modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Label, {
              class: "col-md-10-em col-form-label text-md-end",
              for: _ctx.$id('planNumber')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.planNumber')), 1)
              ]),
              _: 1
            }, 8, ["for"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_CustomSelect, {
                disabled: this.disabled,
                id: _ctx.$id('planNumber'),
                optional: "",
                optionalLabel: _ctx.$t('general.all'),
                options: this.documentVersionNameOptions,
                modelValue: _ctx.documentVersionNameValue,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.documentVersionNameValue) = $event))
              }, null, 8, ["disabled", "id", "optionalLabel", "options", "modelValue"])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Label, {
              class: "col-md-10-em col-form-label text-md-end",
              for: _ctx.$id('planner')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.planner')), 1)
              ]),
              _: 1
            }, 8, ["for"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_CustomSelect, {
                disabled: this.disabled,
                id: _ctx.$id('planner'),
                optional: "",
                optionalLabel: _ctx.$t('general.all'),
                options: this.plannerOptions,
                modelValue: _ctx.plannerOrganisationPersonIdValue,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.plannerOrganisationPersonIdValue) = $event))
              }, null, 8, ["disabled", "id", "optionalLabel", "options", "modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_Label, {
              class: "col-md-10-em col-form-label text-md-end",
              for: _ctx.$id('status')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.status')), 1)
              ]),
              _: 1
            }, 8, ["for"]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_CustomSelect, {
                disabled: this.disabled,
                id: _ctx.$id('status'),
                optional: "",
                optionalLabel: _ctx.$t('general.all'),
                options: this.getStatusOptions(),
                modelValue: this.acceptedModeValue,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.acceptedModeValue) = $event))
              }, null, 8, ["disabled", "id", "optionalLabel", "options", "modelValue"])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_Label, {
              class: "col-md-10-em col-form-label text-md-end",
              for: _ctx.$id('requestedBy')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.requestedBy')), 1)
              ]),
              _: 1
            }, 8, ["for"]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_TreeSelect, {
                disabled: this.disabled,
                id: _ctx.$id('requestedBy'),
                model: _ctx.requestOptions,
                modelValue: this.requestedByValue,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.requestedByValue) = $event))
              }, null, 8, ["disabled", "id", "model", "modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_Label, {
              class: "col-md-10-em col-form-label text-md-end",
              for: _ctx.$id('requestedFor')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.requestedFor')), 1)
              ]),
              _: 1
            }, 8, ["for"]),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_TreeSelect, {
                disabled: this.disabled,
                id: _ctx.$id('requestedFor'),
                model: _ctx.receiverOptions,
                modelValue: this.requestedForValue,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.requestedForValue) = $event))
              }, null, 8, ["disabled", "id", "model", "modelValue"])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("button", {
            type: "reset",
            disabled: this.disabled,
            class: "btn btn-secondary"
          }, _toDisplayString(_ctx.$t("general.button.resetSearchCaption")), 9, _hoisted_22)
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("button", {
            type: "submit",
            disabled: this.disabled,
            class: "btn btn-primary"
          }, _toDisplayString(_ctx.$t("general.button.searchCaption")), 9, _hoisted_24)
        ])
      ])
    ])
  ], 32))
}