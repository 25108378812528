/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { AppContext } from "clazzes-core/base/AppContext";
import { JsonRpc } from "clazzes-core/svc/JsonRpc";
import { TinyLog } from "clazzes-core/log/TinyLog";

import { CdesGwtLoginInfo } from "cdes-api/dto/client/CdesGwtLoginInfo";
import { ClientConfiguration } from "cdes-api/dto/client/ClientConfiguration";
import { PersonVariablesDto } from "cdes-api/dto/person/PersonVariablesDto";

import { CertificateService } from "cdes-api/service/CertificateService";
import { DocumentService } from "cdes-api/service/DocumentService";
import { ChallengeLoginService } from "cdes-api/service/ChallengeLoginService";
import { ContextService } from "cdes-api/service/ContextService";
import { EmailService } from "cdes-api/service/EmailService";
import { NetworkService } from "cdes-api/service/NetworkService";
import { OrganisationService } from "cdes-api/service/OrganisationService";
import { TabSessionCreatorService } from "cdes-api/service/TabSessionCreatorService";
import { ObjectService } from "cdes-api/service/ObjectService";
import { PersonService } from "cdes-api/service/PersonService";
import { PlanDeliverService } from "cdes-api/service/PlanDeliverService";
import { PlanningNotificationService } from "cdes-api/service/PlanningNotificationService";
import { PlotService } from "cdes-api/service/PlotService";
import { ProjectService } from "cdes-api/service/ProjectService";
import { TabSessionContextService } from "cdes-api/service/TabSessionContextService";
import { TaskService } from "cdes-api/service/TaskService";
import { UtilService } from "cdes-api/service/UtilService";
import { ReviewService } from "cdes-api/service/ReviewService";
import { JobStatusService } from "cdes-vue/service/JobStatusService";
import { MainUiInfo } from "cdes-api/dto/client/MainUiInfo";
import { Person } from "cdes-api/dto/Person";
import { PersonVariables } from "cdes-api/dto/PersonVariables";
import { OrganisationPersonJoin } from "cdes-api/joinDto/OrganisationPersonJoin";
import { Network } from "cdes-api/dto/Network";
import { Project } from "cdes-api/dto/Project";
import { SubProject } from "cdes-api/dto/SubProject";
import { UserLoginService } from "cdes-api/service/UserLoginService";
import { ResetPasswordService } from "cdes-api/service/ResetPasswordService";
import * as PersistentHelper from "cdes-vue/util/container/PersistentHelper";
import { ContextMode } from "cdes-vue/voc/ctx/ContextMode";
import { ContextLevel } from "cdes-vue/voc/ctx/ContextLevel";
import { OrganisationParticipationInfo } from "cdes-api/dto/project/OrganisationParticipationInfo";
import { ParticipationPageSearchModel } from "cdes-api/dto/project/ParticipationPageSearchModel";

import { EventEmitter, Listener } from "typed-event-emitter";
import { InfoMessage } from "cdes-api/dto/InfoMessage";
import { VersionInfo } from "cdes-api/dto/util/VersionInfo";

import Cache from "cdes-vue/util/Cache";
import { PageCall } from "cdes-vue/util/router/PageCall";
import { DateHelper } from "clazzes-core/dateTime/DateHelper";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

type EventHandler<Args extends any[]> = (...args: Args) => void;
type EventBinder<Args extends any[]> = (event: EventHandler<Args>) => Listener;

export class CustomEventEmitter extends EventEmitter {
    public emit<Args extends any[]>(event: EventBinder<Args>, ...args: Args) {
        super.emit(event, ...args);
   }
}

let log = new TinyLog("cdes.core.CdesContext");

export class CdesContext extends AppContext {
    private emitter : CustomEventEmitter = new CustomEventEmitter();
    public getEmitter() : CustomEventEmitter {
        return this.emitter;
    }

    private lastPageCall : PageCall = null;

    public readonly onContextWritten = this.emitter.registerEvent<[ ]>();
    public readonly onMainUiInfoLoaded = this.emitter.registerEvent<[ ]>();

    /** Promise for waiting for completion of initialize().
     *  Needed by the beforeEach callback registered in
     *  RouterFactory, to block any attempt to open a Cdes
     *  page until we have been initialized properly.
     */
    private initializePromise : Promise<unknown> = null;
    private _initialized: boolean = false;

    /** Corresponds to browser tab.  Needed as long as the context
     *  is managed via server side, i.e. as long as we have Tapestry
     *  pages.
     */
    private tabSessionId : string;

    private clientConfig : ClientConfiguration;

    private personVariablesDto : PersonVariablesDto;

    passwordExpired = false;
    userPolicyExpired = false;

    person : Person;
    contextLevel : ContextLevel;
    infoMessages : InfoMessage[];
    versionInfo : VersionInfo;
    private personVariables : PersonVariables;
    private opIdToOrganisationPersonJoin : Map<number, OrganisationPersonJoin>;
    private organisationPersonIdToNetworks : Map<number, Network[]> = new Map<number, Network[]>();
    private networks: Map<number, Network> = new Map();
    private networkIdToProjects : Map<number, Project[]> = new Map<number, Project[]>();
    private projects: Map<number, Project> = new Map();
    private projectIdToSubProjects : Map<number, SubProject[]> = new Map<number, SubProject[]>();
    private subProjects: Map<number, SubProject> = new Map();

    certificateService : CertificateService;
    challengeLoginService : ChallengeLoginService;
    contextService : ContextService;
    documentService : DocumentService;
    emailService : EmailService;
    networkService : NetworkService;
    objectService : ObjectService;
    organisationService : OrganisationService;
    personService : PersonService;
    reducedIsolationPersonService : PersonService;
    planDeliverService : PlanDeliverService;
    planningNotificationService : PlanningNotificationService;
    plotService : PlotService;
    projectService : ProjectService;
    tabSessionContextService : TabSessionContextService;
    tabSessionCreatorService : TabSessionCreatorService;
    taskService : TaskService;
    reducedIsolationTaskService : TaskService;
    userLoginService : UserLoginService;
    utilService : UtilService;
    reviewService : ReviewService;
    jobStatusService : JobStatusService;
    resetPasswordService : ResetPasswordService;

    taskAborted : boolean = false;
    participationAborted : boolean = false;

    editedParticipationOrganisationId : number = null;
    editedProjectId : number = null;
    editedOriginalDocumentOrderId : number = null;
    editedTaskId : number = null;

    // Timestamp (utcMillis) of last successful login, see successs handler in LoginDialog.ts
    lastSuccessfulLoginTist : number = null;

    principal : string = null;

    cachedNetworkHandlers : (() => void)[] = [];
    cachedProjectHandlers : (() => void)[] = [];

    doEnableCustomerLogo : boolean;
    doTestInstance : boolean;
    certificateAdministrationGlobal : boolean;
    externalManualLink : string;
    isSuperAdmin : boolean;
    hasEditPerson : boolean;
    hasShowPerson : boolean;
    hasShowOrganisation : boolean;
    hasShowConsortium : boolean;
    hasGenerateReviewReport : boolean;
    hasEditProject : boolean;
    mayShowReviewSection : boolean;
    mayBuek : boolean;
    mayShowAdminMenu : boolean;
    mayShowNetworkSection : boolean;
    costumerLabel : string = null;

    constructor() {
        super();
    }

    public getInitializePromise() : Promise<unknown> {
        return this.initializePromise;
    }

    public getTabSessionId() : string {
        return this.tabSessionId;
    }

    public getUserLocale() : string {
        return this.personVariablesDto?.userLocale;
    }

    public enableCustomerLogo() : boolean {
        return this.doEnableCustomerLogo;
    }

    public isTestInstance() : boolean {
        return this.doTestInstance;
    }

    public getActiveOPOrganisationName() : string {
        let activeOrganisationPersonId : number = this.personVariables.activeOrganisationPersonId;
        return activeOrganisationPersonId != null && this.opIdToOrganisationPersonJoin.has(activeOrganisationPersonId)
            ? this.opIdToOrganisationPersonJoin.get(activeOrganisationPersonId).organisationName
            : "\u2015";
    }

    public getNumberOfOrganisations() : number {
        return this.opIdToOrganisationPersonJoin.size;
    }

    public getOwnOrganisationPersonJoins() : OrganisationPersonJoin[] {
        return Array.from(this.opIdToOrganisationPersonJoin.values());
    }

    /** Set up services, open tab session, start Tapestry session.
     *  We may only call services, and open Cdes pages, once this
     *  function has finished.
     */
    public async initialize() : Promise<unknown> {
        /* NOTE: The corresponding code in old GWT times was located in:
         * at.cdes.gwt.client.cdes_gwt.onModuleLoad()
         * ... calls (among other rather uninteresting things)
         *     at.cdes.gwt.client.AppController.go(HasWidgets)
         *     - Calls setupAppController for binding events
         *     - Emits OpenTabSessionEvent for starting the chain of login events
         *
         * Catching events is defined starting from
         * at.cdes.gwt.client.AppController.setupAppController()
         *
         * Reaction to OpenTabSessionEvent:
         * at.cdes.gwt.client.AppController.bindLoginEvents().new OpenTabSessionEventHandler()
         *        {...}.onCheckUserLoggedIn(OpenTabSessionEvent)
         * ... fetches loginSuffix, and in success case, calls openTabSession
         *
         * Reaction to ShowTapestryPageEvent, defined in bindOtherEvents
         * ... performs some magic concerning the hash parameter.
         *
         */

        if (this.reloginHandler == null) {
            throw new Error("Please set up reloginHandler first.");
        }

        this.initializePromise = Promise.all([
            JsonRpc.initContainerDeferred("/cdes/svc/certificateService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/challengeLoginService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/contextService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/documentService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/emailService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/networkService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/objectService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/organisationService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/personService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/reducedIsolationPersonService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/planDeliverService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/planningNotificationService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/plotService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/projectService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/tabSessionContextService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/tabSessionCreatorService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/taskService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/reducedIsolationTaskService", this.reloginHandler),            
            JsonRpc.initContainerDeferred("/cdes/svc/userLoginService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/utilService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/reviewService", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes-dojo-impl/jobStatus", this.reloginHandler),
            JsonRpc.initContainerDeferred("/cdes/svc/resetPasswordService", this.reloginHandler),
        ]).then((services : JsonRpc[]) => {
            this.processServicesLoaded(services);
            return this.networkService.getAllNetworks();
        }).catch((err : any) => {
            ErrorHelper.processErrorWithoutI18n(err);
            throw new Error("Getting all networks failed: [" + err + "]");
        }).then((networks : Network[]) => {
            console.info("Fetched [" + networks.length + "] networks.");
            return this.tabSessionCreatorService.openTabSession();
        }).catch((err : any) => {
            ErrorHelper.processErrorWithoutI18n(err);
            throw new Error("Setting up services failed: [" + err + "]");
        }).then((loginInfo : CdesGwtLoginInfo) => {
            this.recordLoginInfo(loginInfo);
            return this.emitTapestryGetQuery("restart");
        }).catch((err : any) => {
            ErrorHelper.processErrorWithoutI18n(err);
            throw new Error("Opening tab session failed: [" + err + "]");
        }).then((_response : Response) => {
            return this.personService.getMainUiInfo(this.tabSessionId);
        }).catch((err : any) => {
            ErrorHelper.processErrorWithoutI18n(err);
            throw new Error("Querying mainUiInfo failed: [" + err + "]");
        }).then((mainUiInfo : MainUiInfo) => {
            this.principal = mainUiInfo.principal;
            this.person = mainUiInfo.person;
            this.personVariables = mainUiInfo.personVariables;
            this.infoMessages = mainUiInfo.infoMessages;
            this.versionInfo = mainUiInfo.versionInfo;
            this.doEnableCustomerLogo = mainUiInfo.enableCustomerLogo;
            this.doTestInstance = mainUiInfo.isTestInstance;
            this.certificateAdministrationGlobal = mainUiInfo.certificateAdministrationGlobal;
            this.externalManualLink = mainUiInfo.externalManualLink;
            this.isSuperAdmin = mainUiInfo.isSuperAdmin;
            this.hasEditPerson = mainUiInfo.hasEditPerson;
            this.hasShowPerson = mainUiInfo.hasShowPerson;
            this.hasShowOrganisation = mainUiInfo.hasShowOrganisation;
            this.hasShowConsortium = mainUiInfo.hasShowConsortium;
            this.hasGenerateReviewReport = mainUiInfo.hasGenerateReviewReport;
            this.hasEditProject = mainUiInfo.hasEditProject;
            this.mayShowAdminMenu = mainUiInfo.mayShowAdminMenu;
            this.mayShowReviewSection = mainUiInfo.mayShowReviewSection;
            this.mayBuek = mainUiInfo.mayShowBuek;
            this.mayShowNetworkSection = mainUiInfo.mayShowNetworkSection;
            this.costumerLabel = mainUiInfo.costumerLabel;

            // Set these before setting the ids below.  The setters of e.g.
            // activeOrganisationPersonId need these data structures.
            this.organisationPersonIdToNetworks = mainUiInfo.organisationPersonIdToNetworks;
            for (const networks of this.organisationPersonIdToNetworks.values()) {
                for (const network of networks) {
                    this.networks.set(network.id, network);
                }
            }
            this.networkIdToProjects = mainUiInfo.networkIdToProjects;
            for (const projects of this.networkIdToProjects.values()) {
                for (const project of projects) {
                    this.projects.set(project.id, project);
                }
            }
            this.projectIdToSubProjects = mainUiInfo.projectIdToSubProjects;
            for (const subProjects of this.projectIdToSubProjects.values()) {
                for (const subProject of subProjects) {
                    this.subProjects.set(subProject.id, subProject);
                }
            }

            this.opIdToOrganisationPersonJoin = new Map<number, OrganisationPersonJoin>();
            for (let organisationPersonJoin of mainUiInfo.organisationPersonJoins) {
                let organisationPersonId : number = organisationPersonJoin.organisationPersonId;
                this.opIdToOrganisationPersonJoin.set(organisationPersonId, organisationPersonJoin);
            }
            let activeOrganisationPersonId = this.activeOrganisationPersonId;
            if (activeOrganisationPersonId == null || !(activeOrganisationPersonId in this.opIdToOrganisationPersonJoin)) {
                for (let organisationPersonId of this.opIdToOrganisationPersonJoin.keys()) {
                    this.activeOrganisationPersonId = organisationPersonId;
                    break;
                }
            }

            this._initialized = true;
            this.emitter.emit(this.onMainUiInfoLoaded);
        }, err => {
            ErrorHelper.processErrorWithoutI18n(err);
        });

        return this.initializePromise;
    }

    public setupServices() : Promise<JsonRpc[]> {
        if (this.reloginHandler == null) {
            throw new Error("Please set up reloginHandler first.");
        }

        if (log.isDebugEnabled()) {
            log.debug("Will set up services.");
        }

        let retPromise : Promise<JsonRpc[]> = new Promise<JsonRpc[]>((resolve, reject) => {
            Promise.all([
                JsonRpc.initDeferred("/cdes/svc/tabSessionCreatorServiceWeb", this.reloginHandler),
                // @ts-ignore
            ]).then((services : JsonRpc[]) => {
                this.processServicesLoaded(services);
                resolve(services);
            }, (_err) => {
                reject(retPromise);
            });
        });
        return retPromise;
    }

    private processServicesLoaded(services : JsonRpc[]) : void {
        this.certificateService = services[0] as unknown as CertificateService;
        this.challengeLoginService = services[1] as unknown as ChallengeLoginService;
        this.contextService = services[2] as unknown as ContextService;
        this.documentService = services[3] as unknown as DocumentService;
        this.emailService = services[4] as unknown as EmailService;
        this.networkService = services[5] as unknown as NetworkService;
        this.objectService = services[6] as unknown as ObjectService;
        this.organisationService = services[7] as unknown as OrganisationService;
        this.personService = services[8] as unknown as PersonService;
        this.reducedIsolationPersonService = services[9] as unknown as PersonService;        
        this.planDeliverService = services[10] as unknown as PlanDeliverService;
        this.planningNotificationService = services[11] as unknown as PlanningNotificationService;
        this.plotService = services[12] as unknown as PlotService;
        this.projectService = services[13] as unknown as ProjectService;
        this.tabSessionContextService = services[14] as unknown as TabSessionContextService;
        this.tabSessionCreatorService = services[15] as unknown as TabSessionCreatorService;
        this.taskService = services[16] as unknown as TaskService;
        this.reducedIsolationTaskService = services[17] as unknown as TaskService;        
        this.userLoginService = services[18] as unknown as UserLoginService;
        this.utilService = services[19] as unknown as UtilService;
        this.reviewService = services[20] as unknown as ReviewService;
        this.jobStatusService = services[21] as unknown as JobStatusService;
        this.resetPasswordService = services[22] as unknown as ResetPasswordService;
    }

    public recordLoginInfo(loginInfo : CdesGwtLoginInfo) : void {
        this.tabSessionId = loginInfo.tabSessionId;
        this.clientConfig = loginInfo.clientConfig;
        this.personVariablesDto = loginInfo.personVariables;
        this.userPolicyExpired = loginInfo.userPolicyExpired;
        this.passwordExpired = loginInfo.passwordExpired;
    }

    private getTapestryBaseUrl(): URL {
        const ret = new URL(this.clientConfig.appProtocol + "://" + this.clientConfig.cdesTapestryBaseUrl);
        ret.pathname = this.clientConfig.cdesTapestryAppPath;
        return ret;
    }

    public getTapestryRequestUrl(service : string, parameters: string[] | string = []) : string {
        // Notes about the old implementation:
        //
        // Output something like
        // http://localhost:8081/cdes/app?service=restart&ts=Admin
        // Where: ts is the tabSessionId, in debug mode the user name, otherwise a generated uuid
        //
        // The following settings fetched from the at.cdes.gwt osgi config are relevant:
        // - clientConfig.appProtocol
        // - clientConfig.cdesTapestryBaseUrl
        // - clientConfig.cdesTapestryAppPath
        //
        // Old implementation from at.cdes.gwt.client.tapestry.TapestryUrlHandlerImpl.getBaseURL()
	// private UrlBuilder getBaseUrlBuilder() {
	//    UrlBuilder urlBuilder = new UrlBuilder();
	//    urlBuilder.setProtocol(protocol);
	//    urlBuilder.setHost(host);
	//    if(this.basePath != null)
	//        urlBuilder.setPath(basePath);
	//    return urlBuilder;
        // }

        const url = this.getTapestryBaseUrl();

        url.searchParams.append("service", service);
        if (this.tabSessionId != null) {
            url.searchParams.append("ts", this.tabSessionId);
        }
        if (parameters == null) {
            // IGNORE
        } else if (typeof parameters === "string") {
            url.searchParams.append("sp", parameters);
        } else {
            for (const param of parameters) {
                url.searchParams.append("sp", param);
            }
        }

        return url.toString();
    }

    public getTapestryPageUrl(service : string, serviceParams : string | string[] = []) : string {
        return this.getTapestryRequestUrl(service, serviceParams);
    }

    public emitTapestryGetQuery(service : string) : Promise<Response> {
        let url : string = this.getTapestryRequestUrl(service);
        return this.emitQuery(url, { method : "GET" });
    }

    public getDojoPageUrl(page : string, additionalParams?: Record<string, unknown>) : string {
        let queryParams : URLSearchParams = new URLSearchParams({
            locale : this.getUserLocale(),
            ts : this.tabSessionId,
        });

        let hashParams = new URLSearchParams({
            page,
            ...additionalParams,
        });

        let url : string = "/cdes/frame.html?" + queryParams.toString() + "#" + hashParams.toString();
        return url;
    }

    public getBsPageUrl(page : string, additionalParams?: Record<string, unknown>) : string {
        let queryParams : URLSearchParams = new URLSearchParams({
            locale : this.getUserLocale(),
            ts : this.tabSessionId,
        });

        let hashParams;
        if (additionalParams != null) {
            hashParams = new URLSearchParams({
                foo : "",
                ...additionalParams,
            });
        }

        let url : string = "/cdes-bs.html?" + queryParams.toString() + "#" + page;
        if (hashParams != null) {
            url += "?" + hashParams.toString();
        }
        return url;
    }        

    public getManualUrl() {
        let userLocale = this.getUserLocale();
        let manualLink = this.externalManualLink;
        if (manualLink.indexOf("manual.htm") > -1) {
            if (userLocale == "en") {
                manualLink = manualLink.substring(0, manualLink.indexOf("manual.htm")).concat("manual_en.htm");
            } else if (userLocale == "it") {
                manualLink = manualLink.substring(0, manualLink.indexOf("manual.htm")).concat("manual_it.htm");
            } else if (userLocale == "sl") {
                manualLink = manualLink.substring(0, manualLink.indexOf("manual.htm")).concat("manual_sl.htm");
            }
        }
        return manualLink;
    }

    public triggerRelogin(loginUrl : string, reloginSrc : string) {
        return this.reloginHandler(loginUrl);   
    }

    public getNetworksForOrganisationPersonId(organisationPersonId : number) : Network[] {
        let queryOrgPersonId : number = Number(organisationPersonId);
        let networks : Network[] = this.organisationPersonIdToNetworks.get(organisationPersonId);
        return networks;
    }

    public async getNetworkProjects(networkId : number, activeOrganisationPersonId : number, buekMode : boolean) : Promise<Project[]> {
        if (networkId in this.networkIdToProjects) {
            return Promise.resolve(this.networkIdToProjects.get(networkId));
        } else {
            return this.projectService.getValidProjectsByNetworkIdAndOrgPersonId(networkId, activeOrganisationPersonId, buekMode)
                .then((projects : Project[]) => {
                    this.networkIdToProjects.set(networkId, projects);
                    for (const project of projects) {
                        this.projects.set(project.id, project);
                    }
                    return projects;
                });
        }
    }

    public async getProjectSubProjects(projectId : number, activeOrganisationPersonId : number) : Promise<SubProject[]> {
        if (projectId in this.projectIdToSubProjects) {
            return Promise.resolve(this.projectIdToSubProjects.get(projectId));
        } else {
            return this.projectService.getValidSubProjectsByProjectId(projectId, activeOrganisationPersonId)
                .then((subProjects : SubProject[]) => {
                    this.projectIdToSubProjects.set(projectId, subProjects);
                    for (const subProject of subProjects) {
                        this.subProjects.set(subProject.id, subProject);
                    }
                    return subProjects;
                });
        }
    }

    public get buekMode() : boolean {
        return this.personVariables.preselectedTab == ContextMode.BUEK;
    }

    public set buekMode(buekMode : boolean) {
        if (buekMode) {
            this.personVariables.preselectedTab = ContextMode.BUEK;
        } else {
            this.personVariables.preselectedTab = ContextMode.MAIN;
        }
        this.writeContext();
    }

    public get activeOrganisationPersonId() : number {
        return this.personVariables.activeOrganisationPersonId;
    }

    public set activeOrganisationPersonId(activeOrganisationPersonId : number) {
        let opIdChanged : boolean = this.personVariables.activeOrganisationPersonId != activeOrganisationPersonId;
        this.personVariables.activeOrganisationPersonId = activeOrganisationPersonId;

        let availableNetworks : Network[] = this.getNetworksForOrganisationPersonId(this.personVariables.activeOrganisationPersonId);
        let currNetworkId : number = this.personVariables.activeNetworkId;
        let writeContext : boolean = opIdChanged;
        if (!PersistentHelper.containsPersistentById(availableNetworks, currNetworkId)) {
            this.activeNetworkId = availableNetworks != null && availableNetworks.length > 0 ? availableNetworks[0].id : null;
        } else 
			this.activeNetworkId = currNetworkId;

        if (writeContext) {
            this.writeContext();
			this.invalidateCachedNetwork(this.activeNetworkId);
        }
    }

    public activeOrgPersonHint = false;

    public get activeOrganisationPerson(): OrganisationPersonJoin {
        return this.opIdToOrganisationPersonJoin.get(this.activeOrganisationPersonId);
    }

    public get activeNetworkId() : number {
        return this.buekMode ? this.personVariables.activePnNetworkId : this.personVariables.activeNetworkId;
    }

    public set activeNetworkId(activeNetworkId : number) {
        let networkIdChanged : boolean;

        if (this.buekMode) {
            networkIdChanged = this.personVariables.activePnNetworkId != activeNetworkId;
            this.personVariables.activePnNetworkId = activeNetworkId;
        } else {
            networkIdChanged = this.personVariables.activeNetworkId != activeNetworkId;
            this.personVariables.activeNetworkId = activeNetworkId;
        }

        this.getNetworkProjects(activeNetworkId, this.activeOrganisationPersonId, this.buekMode)
            .then((projects : Project[]) => {
                if (!PersistentHelper.containsPersistentById(projects, this.activeProjectId)) {
                    // This causes a writeContext evaluation in the activeProjectId handler below.
                    // Thus we don't need to writeContext here.
                    this.activeProjectId = (projects != null && projects.length > 0 ? projects[0].id : null);
                } else {
                    // The call chain for setting context components ended here, we don't touch
                    // the activeProjectId right now.  Thus check wether we need to write the
                    // context to the database.
                    if (networkIdChanged) {
                        this.writeContext();
                    }
                }
            });
    }

    public get activeNetwork(): Network {
        return this.networks.get(this.activeNetworkId);
    }

    public get activeProjectId() : number {
        return this.buekMode ? this.personVariables.activePnProjectId : this.personVariables.activeProjectId;
    }

    public set activeProjectId(activeProjectId : number) {
        let projectIdChanged : boolean;
        if (this.buekMode) {
            projectIdChanged = this.personVariables.activePnProjectId != activeProjectId;
            this.personVariables.activePnProjectId = activeProjectId;
        } else {
            projectIdChanged = this.personVariables.activeProjectId != activeProjectId;
            this.personVariables.activeProjectId = activeProjectId;
        }
        if (!this.buekMode) {
            this.getProjectSubProjects(this.activeProjectId, this.activeOrganisationPersonId)
                .then((subProjects : SubProject[]) => {
                    if (!PersistentHelper.containsPersistentById(subProjects, this.activeSubProjectId)) {
                        this.activeSubProjectId
                            = (subProjects != null && subProjects.length > 0 ? subProjects[0].id : null);
                    } else {
                        if (projectIdChanged) {
                            this.writeContext();
                        }
                    }
                });
        } else {
            this.writeContext();
        }
    }

    public get activeProject(): Project {
        return this.projects.get(this.activeProjectId);
    }

    public get activeSubProjectId() : number {
        return this.personVariables.activeSubprojectId;
    }

    public set activeSubProjectId(activeSubProjectId : number) {
        let subProjectIdChanged : boolean = this.personVariables.activeSubprojectId != activeSubProjectId;
        this.personVariables.activeSubprojectId = activeSubProjectId;

        if (subProjectIdChanged) {
            this.writeContext();
        }
    }

    public get activeSubProject(): SubProject {
        return this.subProjects.get(this.activeSubProjectId);
    }

    public invalidateCachedNetwork(networkId : number) : void {
        this.networkIdToProjects.delete(networkId);
        for (let cachedNetworkHandler of this.cachedNetworkHandlers) {
            cachedNetworkHandler();
        }
    }

    public invalidateCachedProject(projectId : number) : void {
        this.projectIdToSubProjects.delete(projectId);
        for (let cachedProjectHandler of this.cachedProjectHandlers) {
            cachedProjectHandler();
        }
    }

    public registerCachedNetworkHandler(handler : () => void) : void {
        this.cachedNetworkHandlers.push(handler);
    }

    public registerCachedProjectHandler(handler : () => void) : void {
        this.cachedProjectHandlers.push(handler);
    }

    private writeContext() : void {
        // Generally: We only call this function if data has actually changed (check at caller side).
        // This is to avoid the risk of flooding the database with requests, just because the client
        // does funny call chains without actual changing anything.

        if (this.buekMode) {
            this.reducedIsolationPersonService.updatePnContext(this.tabSessionId, this.activeOrganisationPersonId,
                                               this.activeNetworkId, this.activeProjectId)
                .then(() => {
                    this.emitter.emit(this.onContextWritten);
                });
        } else {
            this.reducedIsolationPersonService.updateContext(this.tabSessionId, this.activeOrganisationPersonId,
                                             this.activeNetworkId, this.activeProjectId,
                                             this.activeSubProjectId)
                .then(() => {
                    this.emitter.emit(this.onContextWritten);
                });
        }
    }

    public get initialized(): boolean {
        return this._initialized;
    }

    public getTimeZone(): string {
        return "Europe/Vienna";
    }

    // Source of this function: My inability to make Vue $d-function format a date including
    // hour, minute and second.
    public formatUtcSecondsLong(utcSeconds : number, locale : string) : string {
        let dateWithSecondsPattern;
        if (locale != null || locale.startsWith("de")) {
            dateWithSecondsPattern = [ {component : 'date', style : 'decimal', minLength : 2}, ".",
                                       {component : 'month', style : 'decimal', minLength : 2}, '.',
                                       {component : 'year', style : 'decimal', minLength : 4}, " ",
                                       {component : "hour", style : "decimal", length : 2}, ":",
                                       {component : "minute", style : "decimal", length : 2}, ":",
                                       {component : "second", style : "decimal", length : 2}
                                     ];
        } else {
            dateWithSecondsPattern = [ {component : 'year', style : 'decimal', minLength : 4}, "-",
                                       {component : 'month', style : 'decimal', minLength : 2}, '-',
                                       {component : 'date', style : 'decimal', minLength : 2}, " ",
                                       {component : "hour", style : "decimal", length : 2}, ":",
                                       {component : "minute", style : "decimal", length : 2}, ":",
                                       {component : "second", style : "decimal", length : 2}
                                     ];            
        } 
        
        return DateHelper.formatUtcSecondsWithTimeZone(utcSeconds, this.getTimeZone(), dateWithSecondsPattern, locale);
    }

    public setLastPageCall(pageCall : PageCall) : void {
        this.lastPageCall = pageCall;
    }

    public getLastPageCall() : PageCall {
        return this.lastPageCall;
    }
}
