/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, PropType } from "vue";
import ParempiGrid, { ParempiColumn, ColumnGroup, ParempiRowPos } from "cdes-vue/util/grid/ParempiGrid.vue";
import CheckBox from 'cdes-vue/util/form/CheckBox.vue';
import NumberInput from "cdes-vue/util/form/NumberInput.vue";
import Select from "cdes-vue/util/form/Select.vue";
import TextInput from "cdes-vue/util/form/TextInput.vue";
import { LabelTextField } from "cdes-api/dto/review/LabelTextField";
import { LabelTextFieldType } from "cdes-vue/voc/review/LabelTextFieldType";
import { LabelContentInfo } from "cdes-api/dto/review/LabelContentInfo";
import { SelectOption } from "cdes-vue/util/form/Select";
import { LabelFontStyle } from "cdes-vue/voc/review/LabelFontStyle";
import { LabelAlignment } from "cdes-vue/voc/review/LabelAlignment";
import { ReviewCycleNode } from "cdes-api/dto/ReviewCycleNode";
import { ReviewCycleCell } from "cdes-api/dto/ReviewCycleCell";

export default defineComponent({
    components: {
        ParempiGrid,
        CheckBox,
        NumberInput,
        Select,
        TextInput
    },

    // Don't know why outcommenting this leads to a compiler error, although
    // it should be necessary as far as I know.
    //    emits: [ "removeTextField" ],

    computed : {
        contentOptions() : SelectOption[] {
            let options : SelectOption[] = [];

            options.push({ label : this.$t("review.labelTextField.content.name"),
                           value : "${name}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.nameWithoutVersion"),
                           value : "${nameWithoutVersion}"
                         });
			options.push({ label : this.$t("review.labelTextField.content.nameWithoutVersionPartB"),
							value : "${nameWithoutVersionPartB}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.documentVersionPartA"),
                           value : "${version_parta}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.documentVersionPartB"),
                           value : "${version_partb}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.documentVersionStatus"),
                           value : "${version_status}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.asBuildName"),
                           value : "${as_built_name}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.altName"),
                           value : "${alt_name}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.comment"),
                           value : "${comment}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.principalProject"),
                           value : "${principal_project}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.projectCode"),
                           value : "${project_code}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.objectType"),
                           value : "${object_type}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.projectName"),
                           value : "${project_name}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.projectRoute"),
                           value : "${project_route}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.projectStretch"),
                           value : "${project_stretch}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.subProjectCode"),
                           value : "${subProject_code}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.subProjectName"),
                           value : "${subProject_name}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.documentTitle"),
                           value : "${document_title}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.projectKms"),
                           value : "${project_kms}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.objectKms"),
                           value : "${object_kms}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.scale"),
                           value : "${scale}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.objectCode"),
                           value : "${object_code}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.objectName"),
                           value : "${object_name}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.uploaded"),
                           value : "${uploaded}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.uploadSignature"),
                           value : "${upload_sig}"
                         });
            options.push({ label : this.$t("review.labelTextField.content.trackerUrl"),
                           value : "${tracker_url}"
                         });

            return options;
        },
        fontOptions() {
            let options : SelectOption[] = [];
            options.push({
                label : "Helvetica",
                value : "Helvetica"
            });
            options.push({
                label : "Courier",
                value : "Courier"
            });            
            return options;
        },
        fontStyleOptions() {
            let options : SelectOption[] = [
                { value : LabelFontStyle.REGULAR, label : this.$t("review.labelTextField.fontStyle.regular") },
                { value : LabelFontStyle.BOLD, label : this.$t("review.labelTextField.fontStyle.bold") },
                { value : LabelFontStyle.ITALIC, label : this.$t("review.labelTextField.fontStyle.italic") },
                { value : LabelFontStyle.BOLD_ITALIC, label : this.$t("review.labelTextField.fontStyle.boldItalic") }                
            ];
            return options;
        },
        alignmentOptions() {
            let options : SelectOption[] = [
                { value : LabelAlignment.LEFT, label : this.$t("review.labelTextField.alignment.left") },
                { value : LabelAlignment.CENTER, label : this.$t("review.labelTextField.alignment.center") },
                { value : LabelAlignment.RIGHT, label : this.$t("review.labelTextField.alignment.right") },
            ];
            return options;
        }
    },

    data() {
        return {
            columns : this.constructColumns(),
        }
    },

    props: {
        items: {
            type: Array as PropType<LabelTextField[]>,
            default: () => [],
        },
        fieldType : {
            type : String
        },
        labelContentInfo : Object as PropType<LabelContentInfo>,
        alternativeReviewCycleId : Number,
        selectedSourceReviewCycleId : Number
    },

    methods: {
        isAlternative(labelTextField : LabelTextField) : boolean {
            return labelTextField.reviewCycleId == this.alternativeReviewCycleId;
        },

        isSelectedSourceReviewCycle(labelTextField : LabelTextField) : boolean {
            return labelTextField.reviewCycleId == this.selectedSourceReviewCycleId;
        },
        getClass(labelTextField : LabelTextField) : string {
            return this.isAlternative(labelTextField) ? "alternativeReviewCycle" : this.isSelectedSourceReviewCycle(labelTextField) ? "selectedSourceReviewCycle" : "";
        },

        constructColumns() : ParempiColumn<LabelTextField>[] {
            let columns : ParempiColumn<LabelTextField>[] = [];
            if (this.fieldType == LabelTextFieldType.SIGNATURE) {
                columns.push(ParempiColumn.asWidthRowSpanSlot(2.5, "reviewCycleNode", this.$t("general.reviewCycleNode"), "reviewCycleNode"));
            }
            if (this.fieldType == LabelTextFieldType.DOCUMENT_VERSION) {
                columns.push(ParempiColumn.asWidthRowSpanSlot(2.0, "textDropDown", this.$t("general.text"), "textDropDown"));
            }
            if (this.fieldType == LabelTextFieldType.FREE) {
                columns.push(ParempiColumn.asWidthRowSpanSlot(2.0, "textTextField", this.$t("general.text"), "textTextField"));
            }
            if (this.fieldType == LabelTextFieldType.SIGNATURE) {
                columns.push(ParempiColumn.asWidthRowSpanSlot(1.5, "comment", this.$t("general.comment"), "comment"));
                columns.push(ParempiColumn.asWidthRowSpanSlot(1.0, "realm", this.$t("review.labelTextField.realm"), "realm"));
            }
            columns.push(ParempiColumn.asWidthRowSpanSlot(2.0, "lowerLeft", this.$t("review.labelTextField.lowerLeft"), "lowerLeft"));
            columns.push(ParempiColumn.asWidthRowSpanSlot(2.0, "upperRight", this.$t("review.labelTextField.upperRight"), "upperRight"));
            columns.push(ParempiColumn.asWidthRowSpanSlot(1.3, "font", this.$t("review.labelTextField.font"), "font"));
            columns.push(ParempiColumn.asWidthRowSpanSlot(0.7, "fontSize", this.$t("review.labelTextField.fontSize"), "fontSize"));
            columns.push(ParempiColumn.asWidthRowSpanSlot(1.2, "fontStyle", this.$t("review.labelTextField.fontStyleLabel"), "fontStyle"));
            columns.push(ParempiColumn.asWidthRowSpanSlot(1.0, "alignment", this.$t("review.labelTextField.alignmentLabel"), "alignment"));
            columns.push(ParempiColumn.asWidthRowSpanSlot(1.0, "choose", this.$t("general.choose"), "choose"));
            return columns;
        },

        getReviewCycleNodeOptions(labelTextField : LabelTextField) : SelectOption[] {
            let reviewCycleId : number = labelTextField.reviewCycleId;
            let options : SelectOption[] = [];
            if (this.labelContentInfo != null && this.labelContentInfo.label != null
                && this.labelContentInfo.reviewCycleIdToNodes != null) {
                let nodes : ReviewCycleNode[] = this.labelContentInfo.reviewCycleIdToNodes.get(reviewCycleId);
                if (nodes != null) {
                    for (let node of nodes) {
                        let reviewCycleCellId : number = node.reviewCycleCellId;
                        let reviewCycleCell : ReviewCycleCell = this.labelContentInfo.idToReviewCycleCell.get(reviewCycleCellId);
                        let reviewCycleCellName : string = reviewCycleCell != null ? (this.$s(reviewCycleCell.name) + " - ") : "";
                        options.push({
                            label : reviewCycleCellName + this.$s(node.name),
                            value : node.id
                        });
                    }
                }
            }
            options = options.sort((o1, o2) => {
                return o1.label.localeCompare(o2.label);
            });
            
            return options;
        },        
    },
});
