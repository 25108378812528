/* Copyright (C) 2022 ev-i Informationstechnologie GmbH */

import { defineComponent, computed, ref, PropType, toRef, reactive, getCurrentInstance, watchEffect } from "vue";
import { Tab } from "bootstrap";
import CustomSelect from "cdes-vue/util/form/CustomSelect.vue";
import Label from "cdes-vue/util/form/Label.vue";
import { useCtx } from "cdes-vue/util/Ctx";
import FormGrid from "cdes-vue/util/form/FormGrid.vue";
import FormGridLocal from "cdes-vue/util/form/FormGridLocal.vue";
import FormGridMember from "cdes-vue/util/form/FormGridMember.vue";
import FormGridColumn from "cdes-vue/util/form/FormGridColumn.vue";
import LoadedOrPlaceholder from "cdes-vue/util/form/LoadedOrPlaceholder.vue";
import DateInput from "cdes-vue/util/form/DateInput.vue";
import { asyncEagerComputed, loadingPlaceholder, LoadingPlaceholder, mapLoading, writableLoadedOrDefault, loadedOrDefault, toLoadingRef, toLoadingRefArray } from "cdes-vue/util/Prop";
import { ProjectHoliday } from "cdes-api/dto/ProjectHoliday";
import { Project } from "cdes-api/dto/Project";
import { useValidationForm, useValidity } from "cdes-vue/util/directives/ValidationForm";
import Transform from "cdes-vue/util/Transform";
import ValidationError from "cdes-vue/util/form/ValidationError";
import { OrganisationPersonSelectionJoin } from "cdes-api/joinDto/OrganisationPersonSelectionJoin";
import { ObjectPlannerDefinition } from "cdes-api/dto/ObjectPlannerDefinition";
import ObjectPlannerPositionDefinitionListWidget from "cdes-vue/project/project/ObjectPlannerPositionDefinitionListWidget.vue";
import { ObjectPlannerPositionDefinition } from "cdes-api/dto/ObjectPlannerPositionDefinition";
import { ObjectPlannerPositionDefinitionInfo } from "cdes-api/dto/project/ObjectPlannerPositionDefinitionInfo";
import { ProjectPageInfo } from "cdes-api/dto/project/ProjectPageInfo";
import { Localisation } from "cdes-api/dto/Localisation";
import { DocumentNumberPartGroup } from "cdes-api/dto/DocumentNumberPartGroup";
import { ReviewCycleResultOption } from "cdes-api/dto/ReviewCycleResultOption";
import { Person } from "cdes-api/dto/Person";
import { RoleType } from "cdes-api/dto/RoleType";
import Select from "cdes-vue/util/form/Select.vue";
import { SelectOption } from "cdes-vue/util/form/Select";
import { ObjectPlannerDefinitionInfo } from "cdes-api/dto/project/ObjectPlannerDefinitionInfo";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";
import { ProjectStatus } from "cdes-vue/voc/project/ProjectStatus";
import { ProjectStatusInt } from "cdes-vue/voc/project/ProjectStatusInt";

export default defineComponent({

    components: {
        FormGrid,
        FormGridLocal,
        FormGridColumn,
        FormGridMember,
        CustomSelect,
        Label,
        Select,
        DateInput,
        LoadedOrPlaceholder,
        ObjectPlannerPositionDefinitionListWidget,
        Transform,
    },

    props: {
        projectId: {
            type: Number as PropType<number | null>,
        },
        networkId: {
            type: Number as PropType<number | null>,
        },
    },

    computed : {
        isNew() : boolean {
            return this.projectId == null;
        },
        initialized() : boolean {
            return this.projectPageInfo != null;
        },
        positionDefinitions() : ObjectPlannerPositionDefinition[] {
            let positionDefinitions = [];
            for (let positionDefinitionInfo of this.positionInfos) {
                let posInfo : ObjectPlannerPositionDefinitionInfo = positionDefinitionInfo as ObjectPlannerPositionDefinitionInfo;
                let notificateReleasedPositiv = posInfo.notificateReleasedPositiv;
                let notificateReleasedNegativ = posInfo.notificateReleasedNegative;
                let notificateInvalidatedVersion = posInfo.notificateInvalidatedVersion;
                let notificateInvalidatedAll = posInfo.notificateInvalidatedAll;
                let notificateDeleted = posInfo.notificateDeleted;
                let notificateRevised = posInfo.notificateRevised;

                let posDef : ObjectPlannerPositionDefinition = positionDefinitionInfo.objectPlannerPositionDefinition;
                posDef.statusNotification = (notificateReleasedPositiv ? 1 : 0)
                    + (notificateReleasedNegativ ? 2 : 0)
                    + (notificateInvalidatedVersion ? 4 : 0)
                    + (notificateInvalidatedAll ? 8 : 0)
                    + (notificateDeleted ? 16 : 0)
                    + (notificateRevised ? 32 : 0);
                
                positionDefinitions.push(posDef);
            }
            return positionDefinitions;
        },
        project() : Project {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.project : new Project();
        },
        projectCode() : string {
            return this.project.code;
        },
        projectHolidays() : ProjectHoliday[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.projectHolidays : [];
        },
        projectLeaders() : Person[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.projectLeaders : [];
        },
        documentNumberPartGroups() : DocumentNumberPartGroup[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.documentNumberPartGroups : [];
        },
		maxProjectCodeLengthPartGroup() : number {
			let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
			let codeLength = null;
			if (projectPageInfo != null && this.project.documentNumberPartGroupId != null)
				codeLength = projectPageInfo.partGroupIdToMaxProjectCodeLength[this.project.documentNumberPartGroupId];
			let max = codeLength != null ? codeLength : 16;
			return max;
		},
        reviewCycleResultOptions() : ReviewCycleResultOption[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.reviewCycleResultOptions : [];
        },
        localisations() : Localisation[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.localisations : [];
        },
        roleTypes() : RoleType[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.roleTypes : [];
        },        
        organisationPersonSelectionJoins() : OrganisationPersonSelectionJoin[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.organisationPersonSelectionJoins : [];
        },
        existingProjectCodeToId() : Map<string, number> {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            if (projectPageInfo == null) {
                return new Map<string, number>();
            } else {
                let codeToId : Map<string, number> = new Map<string, number>();
                for (let project of projectPageInfo.existingProjects) {
                    if ((this.ctx.buekMode && project.status == ProjectStatusInt.PLANNING_NOTIFICATION_PROJECT)
                        || (!this.ctx.buekMode && project.status != ProjectStatusInt.PLANNING_NOTIFICATION_PROJECT)) {
                        codeToId.set(project.code, project.id);                        
                    }
                }
                return codeToId;
            }
        },
        isCodeDuplicate() : boolean {
            let network = this.ctx.activeNetwork;
            if (!network.ambiguousProjectCodes) {
                let existingId : number = this.existingProjectCodeToId.get(this.project.code);
                return existingId != null && existingId != this.project.id;
            } else {
                return false;
            }
        },
        invalidClass() : string {
            if (this.isCodeDuplicate) {
                return "showProjectCodeFeedback";
            } else {
                return "";
            }
        },
        createdTime() : number {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            let created : number = projectPageInfo != null ?  projectPageInfo.project.created : null;
            return created;
        },
        createdByPerson() : Person {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.createdBy : new Person();
        },
        hasExistingDocuments() : boolean {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null ? projectPageInfo.hasExistingDocuments : false;
        },
        hasObjectPlannerDefinition() : boolean {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null && projectPageInfo.hasObjectPlannerDefinition;
        },
        objectPlannerDefinitionName() : string {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            return projectPageInfo != null && projectPageInfo.objectPlannerDefinitionInfos.length > 0
                && projectPageInfo.objectPlannerDefinitionInfos[0].objectPlannerDefinition != null
                ? projectPageInfo.objectPlannerDefinitionInfos[0].objectPlannerDefinition.name : "";
        },
        positionInfos() : ObjectPlannerPositionDefinitionInfo[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            if (!this.hasObjectPlannerDefinition) {
                let objectPlannerDefinitionInfo : ObjectPlannerDefinitionInfo
                    = this.objectPlannerDefinitionTemplateToInfo.get(this.objectPlannerDefinitionTemplateId);
                return objectPlannerDefinitionInfo != null ? objectPlannerDefinitionInfo.positionInfos : [];
            } else {
                return projectPageInfo != null && projectPageInfo.objectPlannerDefinitionInfos.length > 0
                    ? projectPageInfo.objectPlannerDefinitionInfos[0].positionInfos : [];
            }
        },
        objectPlannerDefinitionTemplateToInfo() : Map<number, ObjectPlannerDefinitionInfo> {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            let templateIdToInfo : Map<number, ObjectPlannerDefinitionInfo> = new Map<number, ObjectPlannerDefinitionInfo>();
            if (projectPageInfo != null) {
                for (let objectPlannerDefinitionInfo of projectPageInfo.objectPlannerDefinitionInfos) {
                    templateIdToInfo.set(objectPlannerDefinitionInfo.objectPlannerDefinition.templateId, objectPlannerDefinitionInfo);
                }
            }
            return templateIdToInfo;
        },
        objectPlannerDefinitionOptions() : SelectOption[] {
            let projectPageInfo : ProjectPageInfo = this.projectPageInfo as ProjectPageInfo;
            let options : SelectOption[] = [];
            if (projectPageInfo != null) {
                for (let objectPlannerDefinitionInfo of projectPageInfo.objectPlannerDefinitionInfos) {
                    options.push({
                        label : objectPlannerDefinitionInfo.objectPlannerDefinition.name,
                        value : objectPlannerDefinitionInfo.objectPlannerDefinition.templateId
                    });
                }
            }
            return options;
        },

        codeFeedbackClass() : string {
            return !this.initialized || (this.project != null && this.project.code != null && this.project.code.trim().length > 0)
                ? "" : "mustField";
        },

        nameFeedbackClass() : string {
            return !this.initialized || (this.project != null && this.project.name != null && this.project.name.trim().length > 0)
                ? "" : "mustField";
        },

        routeNameFeedbackClass() : string {
            return !this.initialized || (this.project != null && this.project.routeName != null
                && this.project.routeName.trim().length > 0)
                ? "" : "mustField";
        },

        stretchKmFromFeedbackClass() : string {
            return !this.initialized || (this.project != null && this.project.stretchKmFrom != null)
                ? "" : "mustField";
        },

        stretchKmToFeedbackClass() : string {
            return !this.initialized || (this.project != null && this.project.stretchKmTo != null)
                ? "" : "mustField";
        },                        

        localisationIdFeedbackClass() : string {
            return !this.initialized || (this.project != null && this.project.localisationId != null) ? "" : "mustField";
        },

        documentNumberPartGroupIdFeedbackClass() : string {
            return !this.initialized || (this.project != null && this.project.documentNumberPartGroupId != null) ? "" : "mustField";
        },

        ok() : boolean {
            let positionInfosOk = true;
            for (let positionInfo of this.positionInfos) {
                positionInfosOk = positionInfosOk && positionInfo.objectPlannerPositionDefinition != null
                    && positionInfo.objectPlannerPositionDefinition.name != null
                    && positionInfo.objectPlannerPositionDefinition.name.length > 0;
            }
            
            return this.project != null
                && this.project.code != null && this.project.code.trim().length > 0
                && this.project.name != null && this.project.name.trim().length > 0
                && this.project.routeName != null && this.project.routeName.trim().length > 0
                && this.project.stretchKmFrom != null
                && this.project.stretchKmTo != null
                && this.project.localisationId != null
                && this.project.documentNumberPartGroupId != null
                && positionInfosOk;
        },

        /*
        const isCodeDuplicate = asyncEagerComputed(() => {
            const infoValue = info.value;
            // separate ifs for typescript.
            if (networkId.value === loadingPlaceholder) {
                return loadingPlaceholder;
            }
            if (infoValue === loadingPlaceholder) {
                return loadingPlaceholder;
            }

            return ctx.projectService.getByCode(infoValue.project.code, networkId.value)
            .then(projects => projects.filter(project => project.id !== infoValue.project.id).length > 0);
        }, loadingPlaceholder);        
        */
    },

    data() {
        const {
            transformer: formTransformer,
            wasValidated,
        } = useValidationForm({
            // @ts-ignore
            validationFunction: form => this.validate(form),
            // @ts-ignore
            onSubmit: () => this.saveProject(),
        });

        const {
            transformer: fromKmTransformer,
            validity: fromKmValidity
        } = useValidity();

        const {
            transformer: toKmTransformer,
            validity: toKmValidity
        } = useValidity();

        return {
            formTransformer,
            wasValidated,
            fromKmTransformer,
            fromKmValidity,
            toKmTransformer,
            toKmValidity,
            projectPageInfo : null,
            projectLeaderId : null,
            temporaryHolidayName : "",
            temporaryHolidayStartDate : null,
            temporaryHolidayEndDate : null,
            objectPlannerDefinitionTemplateId : null
        };
    },

    mounted() {
        if (this.projectId != null) {
            this.ctx.projectService.getProjectEditInfo(this.ctx.activeOrganisationPersonId, this.projectId)
                .then((projectPageInfo : ProjectPageInfo) => {
                    this.projectPageInfo = projectPageInfo;

                    if (!this.hasObjectPlannerDefinition && this.objectPlannerDefinitionOptions.length > 0) {
                        this.objectPlannerDefinitionTemplateId = this.objectPlannerDefinitionOptions[0].value;
                    }
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });
        } else {
            this.ctx.projectService.getProjectCreateInfo(this.ctx.activeOrganisationPersonId, this.networkId)
                .then((projectPageInfo : ProjectPageInfo) => {
                    this.projectPageInfo = projectPageInfo;

                    if (!this.hasObjectPlannerDefinition && this.objectPlannerDefinitionOptions.length > 0) {
                        this.objectPlannerDefinitionTemplateId = this.objectPlannerDefinitionOptions[0].value;
                    }                    
                }, err => {
                    ErrorHelper.processError(this.$t, this.$d, err);
                });                 
        }
    },
    
    
/*
    setup(props) {
        const ctx = useCtx();
        const addRow = ref(null as (null | HTMLElement));

        const info = asyncEagerComputed(() => {
            if (props.projectId != null) {
                return ctx.projectService.getProjectEditInfo(props.projectId).then(reactive);
            } else {
                return reactive({
                    project: Object.assign(new Project(), {
                        createdById: ctx.person.id,
                        networkId: props.networkId,
                        status: 0,
                        access: 2,
                        withParticipantsSubProjectRestriction: false,
                        ignorePlotOrderReceivedSteps: false,
                        usePDCStartDateForTasks: false,
                    }),
                    projectHolidays: [],
                    createdBy: ctx.person,
                    projectLeader: null,
                    objectPlannerDefinition : Object.assign(new ObjectPlannerDefinition(), {
                        name : ""
                    }),
                    positionInfos : [],
                    roleTypes : []
                });
            }
        }, loadingPlaceholder);

        const networkId = computed(() => mapLoading(info => info.project.networkId, info.value) ?? props.networkId);
        const documentNumberPartGroups = asyncEagerComputed(() => mapLoading(networkId => ctx.projectService.getDocumentNumberPartGroups(networkId), networkId.value), loadingPlaceholder);
        const reviewCycleResultOptions = asyncEagerComputed(() => mapLoading(networkId => ctx.projectService.getReviewCycleResultOptions(networkId), networkId.value), loadingPlaceholder);
        const localisations = asyncEagerComputed(() => ctx.projectService.getLocalisations(), loadingPlaceholder);
        const organisationPersons = asyncEagerComputed(() => props.projectId == null ? ctx.personService.getByOrganisation(ctx.activeOrganisationPerson.organisationId) : null, loadingPlaceholder);

        const temporaryHolidayName = ref("");
        const temporaryHolidayStartDate = ref(null as (null | number));
        const temporaryHolidayEndDate = ref(null as (null | number));
        const projectLeaderId = ref<number>(ctx.activeOrganisationPersonId);

        const inst = getCurrentInstance().proxy;

        const isCodeDuplicate = asyncEagerComputed(() => {
            const infoValue = info.value;
            // separate ifs for typescript.
            if (networkId.value === loadingPlaceholder) {
                return loadingPlaceholder;
            }
            if (infoValue === loadingPlaceholder) {
                return loadingPlaceholder;
            }

            return ctx.projectService.getByCode(infoValue.project.code, networkId.value)
            .then(projects => projects.filter(project => project.id !== infoValue.project.id).length > 0);
        }, loadingPlaceholder);

        const hasExistingDocuments = asyncEagerComputed(() => {
            if (info.value === loadingPlaceholder) {
                return loadingPlaceholder;
            }
            if (info.value.project.id == null) {
                return false;
            }

            return ctx.projectService.hasDocuments(info.value.project.id);
        }, loadingPlaceholder);

        const codeInput = ref<null | HTMLInputElement>(null);

        watchEffect(() => {
            if (codeInput.value == null) {
                return;
            }
            codeInput.value.setCustomValidity(isCodeDuplicate.value === true ? ValidationError.DUPLICATE : ValidationError.NONE);
        });

        const {
            transformer: formTransformer,
            wasValidated,
        } = useValidationForm({
            // @ts-ignore
            validationFunction: form => inst.validate(form),
            // @ts-ignore
            onSubmit: () => inst.saveProject(),
        });

        const {
            transformer: fromKmTransformer,
            validity: fromKmValidity
        } = useValidity();

        const {
            transformer: toKmTransformer,
            validity: toKmValidity
        } = useValidity();

        return {
            codeInput,
            formTransformer,
            wasValidated,
            isCodeDuplicate,
            addRow,
            info,
            fromKmTransformer,
            fromKmValidity,
            toKmTransformer,
            toKmValidity,
            hasExistingDocuments,
            code: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "code"), ""),
            name: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "name"), ""),
            principalProject: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "principalProject"), ""),
            route: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "routeName"), ""),
            stretch: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "stretchName"), ""),
            from: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "stretchKmFrom"), ""),
            to: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "stretchKmTo"), ""),
            comment: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "comment"), ""),
            localisationId: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "localisationId"), null),
            documentNumberPartGroupId: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "documentNumberPartGroupId"), null),
            allowedResultOptionId: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "allowedResultOptionId"), null),
            ignorePlotOrderReceivedSteps: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "ignorePlotOrderReceivedSteps"), false),
            withParticipantsSubProjectRestriction: writableLoadedOrDefault(toLoadingRef(toLoadingRef(info, "project"), "withParticipantsSubProjectRestriction"), false),
            objectPlannerDefinitionName : writableLoadedOrDefault(toLoadingRef(toLoadingRef(
                toLoadingRefArray(info, "objectPlannerDefinitionInfos", 0), "objectPlannerDefinition"), "name"), ""),
            positionInfos : writableLoadedOrDefault(toLoadingRef(toLoadingRefArray(info, "objectPlannerDefinitionInfos", 0),
                                                                 "positionInfos"), []),
                                                                 
            createdBy: computed(() => mapLoading(info => info.createdBy,info.value)),
            projectLeader: computed(() => mapLoading(info => info.projectLeader,info.value)),
            organisationPersons,
            projectLeaderId,
            isNew: computed(() => props.projectId == null),
            localisations,
            documentNumberPartGroups,
            reviewCycleResultOptions,
            holidays: computed(() => loadedOrDefault(mapLoading(info => reactive(info.projectHolidays), info.value), () => [])),
            temporaryHolidayName,
            temporaryHolidayStartDate,
            temporaryHolidayEndDate,
            loadingPlaceholder,
            mapLoading,
            loadedOrDefault,
        };
    },
*/
    methods: {
        addHoliday(): void {
            if (!this.initialized) {
                return;
            }
            if (!((this.$refs.temporaryHolidayNameRef as HTMLInputElement).checkValidity()
                && (this.$refs.temporaryHolidayStartDateRef as typeof DateInput).input.checkValidity()
                && (this.$refs.temporaryHolidayEndDateRef as typeof DateInput).input.checkValidity())) {
                (this.$refs.addRow as HTMLElement).classList.add("was-validated");
                return;
            } else {
                (this.$refs.addRow as HTMLElement).classList.remove("was-validated");
            }
            this.projectHolidays.push(Object.assign(new ProjectHoliday(), {
                projectId: this.project.id,
                description: this.temporaryHolidayName,
                startDate: this.temporaryHolidayStartDate,
                endDate: this.temporaryHolidayEndDate,
            }));
            this.temporaryHolidayName = "";
            this.temporaryHolidayStartDate = null;
            this.temporaryHolidayEndDate = null;
        },
        deleteHoliday(_holiday: ProjectHoliday, index: number): void {
            this.projectHolidays.splice(index, 1);
        },
        validate(form: HTMLFormElement): boolean {
            for (const element of form.elements as Iterable<HTMLInputElement>) {
                if (element !== this.$refs.temporaryHolidayNameRef
                    // @ts-ignore
                    && element !== this.$refs.temporaryHolidayStartDateRef.input
                    // @ts-ignore
                    && element !== this.$refs.temporaryHolidayEndDateRef.input
                    && !element.checkValidity()) {
                    return false;
                }
            }
            return true;
        },
        saveProject(): void {
            if (!this.initialized) {
                return;
            }
            if (this.isCodeDuplicate) {
                return;
            }

            let objectPlannerDefinition : ObjectPlannerDefinition;
            if (!this.hasObjectPlannerDefinition) {
                let objectPlannerDefinitionInfo = this.objectPlannerDefinitionTemplateToInfo
                    .get(this.objectPlannerDefinitionTemplateId);
                objectPlannerDefinition = objectPlannerDefinitionInfo.objectPlannerDefinition;
            } else {
                objectPlannerDefinition = null;
            }

            this.ctx.projectService.saveProject(this.ctx.activeOrganisationPersonId, {
                // @ts-ignore
                project: this.project,
                projectHolidays: this.projectHolidays,
                projectLeaderOrganisationPersonId: this.projectLeaderId,
                objectPlannerDefinition : objectPlannerDefinition,
                positionDefinitions : this.positionDefinitions
            }).then(() => {
                this.ctx.invalidateCachedNetwork(this.networkId);
                this.ctx.editedProjectId = this.project.id;
                this.$router.back();
            }, err => {
                window.alert(this.$t("project.edit.saveError", { timestamp : this.$d(new Date(), "long") }));
            });
        },
        cancel(): void {
            this.ctx.editedProjectId = this.project.id;
            this.$router.back();
        },
        renderProjectLeader(join: OrganisationPersonSelectionJoin): string {
            let ret = `${join.personSurName} ${join.personGivenName}`;

            if (join.organisationPersonOrganisationalUnitName != null) {
                ret += ` (${join.organisationPersonOrganisationalUnitName})`;
            }

            return ret;
        },
        addPosition() {
            let positionInfo : ObjectPlannerPositionDefinitionInfo = new ObjectPlannerPositionDefinitionInfo();
            positionInfo.objectPlannerPositionDefinition = new ObjectPlannerPositionDefinition();
//            positionInfo.objectPlannerPositionDefinition.definitionId = this.obj¹
            positionInfo.roleType = new RoleType();
            positionInfo.notificateDeleted = false;
            positionInfo.notificateInvalidatedAll = false;
            positionInfo.notificateInvalidatedVersion = false;
            positionInfo.notificateReleasedNegative = false;
            positionInfo.notificateReleasedPositiv = false;
            positionInfo.notificateRevised = false;

            this.positionInfos.push(positionInfo);
        },
        deleteItem(deleteIdx : number) {
            if (window.confirm(this.$t("project.edit.objectPlannerDefinitions.reallyDeleteQuestion"))) {
                if (deleteIdx != null) {
                    this.positionInfos.splice(deleteIdx, 1);
                }
            }
        }
    },
});
