/* Copyright (C) 2021 ev-i Informationstechnologie GmbH */

import { Tab } from "bootstrap";
import { defineComponent, PropType, toRef, watch } from "vue";
import { TinyLog } from "clazzes-core/log/TinyLog";
import { InfoMessage } from "cdes-api/dto/InfoMessage";
import { ErrorHelper } from "cdes-vue/util/ErrorHelper";

const log = new TinyLog("cdes.layout.main");

export enum MainMenuSection {
    REVIEW = "plan",
    BUEK = "buek",
    ADMIN = "admin",
    HELP = "hilfe",
    USER = "user"

/*    DOCUMENT = "pills-document-review",
    BUEK = "pills-buek",
    ADMIN = "pills-admin",
    HELP = "pills-help"*/
}

interface MenuEntry {
    id : string;
    labelKey : string;
    onlyAdmin : boolean;
    onlyBuek : boolean;
    subMenu? : SubMenuSection[];
}

interface SubMenuEntry {
    id? : string;        // E.g. buek-notifications (we assume that this is unique)
    labelKey : string;
    icon : string;
    target? : string;    // E.g. buek/notifications
    pageKey : string;    // E.g. notifications
    onlySuperAdmin? : boolean;
    onlyBuek? : boolean;
    onlyEditProject? : boolean;
    onlyEditPerson? : boolean;
    onlyShowPerson? : boolean;
    onlyGenerateReviewReport? : boolean;
    certificateAdministrationGlobal? : boolean;
}

interface SubMenuSection {
    id : string;
    labelKey : string;
    entries : SubMenuEntry[];
    onlyBuek? : boolean,
    noBuekOffset? : number,
    pos : number;   //index
}

class MenuFactory {
    public static constructMenu() : MenuEntry[] {
        return [ MenuFactory.constructMenuSection(MainMenuSection.REVIEW, "mainMenu.documentReviewCaption", false, false,
                                                  [ MenuFactory.constructTaskSubMenuSection(),
                                                    MenuFactory.constructDocumentSubMenuSection(),
                                                    MenuFactory.constructProjectSubMenuSection(),
                                                    MenuFactory.constructNetworkSubMenuSection() ]),
                 MenuFactory.constructMenuSection(MainMenuSection.BUEK, "mainMenu.buekCaption", false, true,
                                                  [ MenuFactory.constructBuekTaskSubMenuSection(),
                                                    MenuFactory.constructBuekNotificationSubMenuSection(),
                                                    MenuFactory.constructBuekProjectSubMenuSection(),
                                                    MenuFactory.constructBuekNetworkSubMenuSection() ]),
                 MenuFactory.constructMenuSection(MainMenuSection.ADMIN, "mainMenu.adminCaption", true, false,
                                                  [ /*MenuFactory.constructAdminProjectSubMenuSection(),*/
                                                    MenuFactory.constructAdminNetworkSubMenuSection(),
                                                    MenuFactory.constructAdminGlobalSubMenuSection(),
                                                    MenuFactory.constructAdminBuekSubMenuSection() ]),
//                                                    MenuFactory.constructAdminOtherSubMenuSection() ]),
                 MenuFactory.constructMenuSection(MainMenuSection.HELP, "mainMenu.helpCaption", false, false,
                                                  [ MenuFactory.constructHelpSubMenuSection() ])
               ];
    }

    private static constructMenuSection(mainMenuSection : MainMenuSection,
                                        labelKey : string, onlyAdmin : boolean,
                                        onlyBuek : boolean, subMenus : SubMenuSection[]) : MenuEntry {
        for (let subMenu of subMenus) {
            for (let subMenuEntry of subMenu.entries) {
                subMenuEntry.id = mainMenuSection + "-" + subMenuEntry.pageKey;
                subMenuEntry.target = "/" + mainMenuSection + "/" + subMenuEntry.pageKey;
            }
        }
        return {
            id : mainMenuSection,
            labelKey : labelKey,
            onlyAdmin : onlyAdmin,
            onlyBuek : onlyBuek,
            subMenu : subMenus
        };
    }

    private static constructTaskSubMenuSection() : SubMenuSection {
        return {
            id : "task-section",
            labelKey : null,
            entries : [
                { labelKey : "mainMenu.taskCaption", icon : "aufgaben", pageKey : "aufgaben" }
            ],
            pos : 1
       };
    }

    private static constructDocumentSubMenuSection() : SubMenuSection {
        return {
            id : "document-section",
            labelKey : "mainMenu.documentsSection",
            entries : [
                { labelKey : "mainMenu.documentListCaption", icon : "planverzeichnis", pageKey : "verzeichnis" },
                { labelKey : "mainMenu.planDeliverCatalogueCaption", icon : "planlieferkatalog", pageKey : "liefern" },
                { labelKey : "mainMenu.reviewStatusCaption", icon : "pruefstatus", pageKey : "pruefstatus", onlySuperAdmin : true },
                { labelKey : "mainMenu.originalsCaption", icon : "originale", pageKey : "originale" },
            ],
            pos : 2
        };
    }

    private static constructProjectSubMenuSection() : SubMenuSection {
        return {
            id : "project-section",
            labelKey : "mainMenu.projectSection",
            entries : [
                { labelKey : "mainMenu.objectCaption", icon : "objekte", pageKey : "objekte/liste" },
//                { labelKey : "mainMenu.objectCaptionOld", icon : "objekte", pageKey : "objekteOld", onlySuperAdmin : true },
                { labelKey : "mainMenu.instanceCaption", icon : "pruefblatt", pageKey : "objekte/pruefblaetter/liste" },
                { labelKey : "mainMenu.projectParticipantCaption", icon : "projektbeteiligte", pageKey : "teilnehmer" },
                { labelKey : "mainMenu.projectCaption", icon : "projekte", pageKey : "projekte" },
                { labelKey : "mainMenu.plotOrderCaption", icon : "plotauftraege", pageKey : "plotauftr" },
                { labelKey : "mainMenu.plotTemplateCaption", icon : "plotvorlagen", pageKey : "plotvorlagen", onlyEditProject : true }
            ],
            pos : 3
        };
    }

    private static constructNetworkSubMenuSection() : SubMenuSection {
        return {
            id : "network-section",
            labelKey : "mainMenu.networkSection",
            entries : [
                { labelKey : "mainMenu.personCaption", icon : "personen", pageKey : "planPersonen", onlyShowPerson : true },
                { labelKey : "mainMenu.organisationCaption", icon : "organisationen", pageKey : "planOrgen" },
                { labelKey : "mainMenu.consortiumCaption", icon : "argen", pageKey : "planArgen" },
                { labelKey : "mainMenu.masterDataCaption", icon : "stammdaten", pageKey : "stammdaten/liste" },
//                { labelKey : "mainMenu.masterDataCaptionOld", icon : "stammdaten", pageKey : "stammdaten_alt", onlySuperAdmin : true },
                { labelKey : "mainMenu.labelCaption", icon: "musterplankoepfe", pageKey: "musterplan"},
                { labelKey : "mainMenu.certificateCaption", icon : "zertifikate", pageKey : "zert", onlyEditPerson : true, certificateAdministrationGlobal : true },
            ],
            pos : 4
        };
    }

    private static constructBuekTaskSubMenuSection() : SubMenuSection {
        return {
            id : "buek-task-section",
            labelKey : null,
            entries : [
                { labelKey : "mainMenu.taskCaption", icon : "buekAufgaben", pageKey : "buekAufgaben" }
            ],
            pos : 1
        };
    }

    private static constructBuekNotificationSubMenuSection() : SubMenuSection {
        return {
            id : "buek-notification-section",
            labelKey : "mainMenu.notificationSection",
            entries : [
                { labelKey : "mainMenu.planningNotificationCaption", icon : "buekBescheide", pageKey : "bescheide" },
                { labelKey : "mainMenu.planningNotificationOrderCaption", icon : "buekVorgaben", pageKey : "vorgaben" },
                { labelKey : "mainMenu.reportingCaption", icon : "buekReporting", pageKey : "reporting", onlyGenerateReviewReport : true }
            ],
            pos : 2
        };
    }

    private static constructBuekProjectSubMenuSection() : SubMenuSection {
        return {
            id : "buek-project-section",
            labelKey : "mainMenu.projectSection",
            entries : [
                { labelKey : "mainMenu.projectParticipantCaption", icon : "projektbeteiligte", pageKey : "teilnehmerBuek" },
                { labelKey : "mainMenu.projectCaption", icon : "buekProjekte", pageKey : "buekProjekte" }
            ],
            pos : 3
        };
    }

    private static constructBuekNetworkSubMenuSection() : SubMenuSection {
        return {
            id : "buek-network-section",
            labelKey : "mainMenu.networkSection",
            entries : [
                { labelKey : "mainMenu.personCaption", icon : "personen", pageKey : "buekPersonen", onlyShowPerson : true },
                { labelKey : "mainMenu.organisationCaption", icon : "organisationen", pageKey : "buekOrgen" },
                { labelKey : "mainMenu.consortiumCaption", icon : "argen", pageKey : "buekArgen" },
            ],
            pos :4
        };
    }

    private static constructAdminProjectSubMenuSection() : SubMenuSection {
        return {
            id : "admin-project-section",
            labelKey : "mainMenu.projectSection",
            entries : [
//                { labelKey : "mainMenu.reviewOptionsCaption", icon : "pruefoptionen", pageKey : "prjpruefoptionen" }
//                { labelKey : "mainMenu.plotTemplateCaption", icon : "plotvorlagen", pageKey : "plotvorlagen" }
            ],
            pos : 1
        };
    }

    private static constructAdminNetworkSubMenuSection() : SubMenuSection {
        return {
            id : "admin-network-section",
            labelKey : "mainMenu.networkSection",
            entries : [
//                { labelKey : "mainMenu.projectCaption", icon : "projekte", pageKey : "projekte_alt" },
//                { labelKey : "mainMenu.projectCaption", icon : "projekte", pageKey : "projekte" },
                { labelKey : "mainMenu.personCaption", icon : "personen", pageKey : "adminPersonen" },
                { labelKey : "mainMenu.organisationCaption", icon : "organisationen", pageKey : "adminOrgen" },
                { labelKey : "mainMenu.consortiumCaption", icon : "argen", pageKey : "adminArgen" },
                { labelKey : "mainMenu.certificateCaption", icon : "zertifikate", pageKey : "adminZert", onlyEditPerson : true },
//                { labelKey : "mainMenu.roleCaption", icon : "rollen", pageKey : "rollenAlt" },
                { labelKey : "mainMenu.roleCaption", icon : "rollen", pageKey : "rollen" },
                { labelKey : "mainMenu.masterDataCaption", icon : "stammdaten", pageKey : "stammdaten/liste" },
//                { labelKey : "mainMenu.masterDataCaptionOld", icon : "stammdaten", pageKey : "stammdaten_alt", onlySuperAdmin : true },
//                { labelKey : "mainMenu.labelTemplateCaption", icon : "musterplankoepfe", pageKey : "musterplan_alt" },
                { labelKey : "mainMenu.labelCaption", icon: "musterplankoepfe", pageKey: "musterplan"},
                { labelKey : "mainMenu.jobCaption", icon: "netzwerke", pageKey: "adminJobList"}
            ],
            pos : 2
        };
    }

    private static constructAdminGlobalSubMenuSection() : SubMenuSection {
        return {
            id : "admin-global-section",
            labelKey : "mainMenu.globalSection",
            noBuekOffset : -1,
            entries : [
                { labelKey : "mainMenu.reviewCycleCaption", icon : "prueflauf", pageKey : "prueflauf" },
                { labelKey : "mainMenu.networkCaption", icon : "netzwerke", pageKey : "netzwerke/liste" },
//                { labelKey : "mainMenu.networkCaptionOld", icon : "netzwerke", pageKey : "netzwerke" , onlySuperAdmin : true },
                { labelKey : "mainMenu.masterDataCaption", icon : "stammdaten", pageKey : "globalestammdaten" },
                { labelKey : "mainMenu.globalRolesCaption", icon : "globaleRollen", pageKey : "globalerollen" },
                { labelKey : "mainMenu.caCaption", icon : "ca", pageKey : "ca" },
            ],
            pos : 3
        };
    }

    private static constructAdminBuekSubMenuSection() : SubMenuSection {
        return {
            id : "admin-other-section",
            labelKey : "mainMenu.buekSection",
            onlyBuek : true,
            entries : [
                { labelKey : "mainMenu.settingsCaption", icon : "buekEinstellungen", pageKey : "einstellungen", onlyBuek : true }
            ],
            pos : 4
        };
    }

    /*
    private static constructAdminOtherSubMenuSection() : SubMenuSection {
        return {
            id : "admin-other-section",
            labelKey : "mainMenu.otherSection",
            entries : [
                { labelKey : "mainMenu.infoCaption", icon : "infos", pageKey : "info" },
                { labelKey : "mainMenu.emailCaption", icon : "emails", pageKey : "emails" },
                { labelKey : "mainMenu.statisticCaption", icon : "statistik", pageKey : "statistik" }
            ],
            pos : 1
        };
    } */

    private static constructHelpSubMenuSection() : SubMenuSection {
        return {
            id : "help-section",
            labelKey : null,
            entries : [
                { labelKey : "mainMenu.infoCaption", icon : "infos", pageKey : "info", onlyEditPerson : true  },
                { labelKey : "mainMenu.emailCaption", icon : "emails", pageKey : "emails" },
                { labelKey : "mainMenu.statisticCaption", icon : "statistik", pageKey : "statistik" },
                { labelKey : "mainMenu.supportCaption", icon : "support", pageKey : "support" },
                { labelKey : "mainMenu.manualCaption", icon : "handbuch", pageKey : "manual" }
    //            { labelKey : "mainMenu.aboutCaption", icon : "ueber", pageKey : "about" }
            ],
            pos : 1
        };
    }
}

export default defineComponent({
    props : {
        activeEntry : {
            type : String as PropType<MainMenuSection>,/*
            validator : (value : string) => {
                return [ MainMenuSection.ADMIN.toString(), MainMenuSection.BUEK.toString(),
                         MainMenuSection.DOCUMENT.toString(), MainMenuSection.HELP.toString() ].includes(value);
            }*/
        },

        menuEntries : {
            type : Array,
            default : MenuFactory.constructMenu
        },

        infoMessages: {
            type: Array as PropType<InfoMessage[]>,
        },
    },

    emits: ["openInfoMessages", "menuButtonClicked", "openAbout"],

    mounted() : void {
        if (this.activeEntry !== MainMenuSection.REVIEW) {
            this.processActiveEntryChanged(this.activeEntry as MainMenuSection, null);
        }
    },

    data() {
        return {
            clickedMainMenuSection : null
        }
    },

    methods : {
        mayShow(entry : MenuEntry) : boolean {
            if (entry.id == MainMenuSection.HELP) {
                return true;
            } else if (entry.onlyAdmin) {
                return this.ctx.mayShowAdminMenu;
            } else if (entry.onlyBuek) {
                return this.ctx.mayBuek;
            } else {
                return this.ctx.mayShowReviewSection;
            }
        },

        mayShowSubItem(subItemSection : SubMenuSection) : boolean {
            if (subItemSection.id == "network-section" || subItemSection.id == "buek-network-section") {
                return this.ctx.mayShowNetworkSection;
            } else if (subItemSection.id == "admin-other-section") {
                return this.ctx.mayBuek;
            }  else {
				return (/*subItemSection.onlyBuek && */this.ctx.mayBuek) || this.ctx.mayShowReviewSection;
			}
        },

        menuButtonClicked(entry : SubMenuEntry) : void {
            this.$emit("menuButtonClicked");
        },

        activateMainMenuButton(entry : MainMenuSection) : void {
            this.clickedMainMenuSection = entry;
        },

        isActiveMainMenuSection(entry : MainMenuSection) : boolean {
            if (this.clickedMainMenuSection != null) {
                return entry == this.clickedMainMenuSection;
            } else {
                return entry == this.activeEntry;
            }
        },

        processActiveEntryChanged(newActiveEntry : MainMenuSection, oldActiveEntry : MainMenuSection) : void {
            // Don't change active main menu, if user chooses something like /user/einstellungen
            if (newActiveEntry == MainMenuSection.USER) {
                return;
            }

            // Happens e.g. if the user chooses some unknown route
            if (newActiveEntry == null) {
                return;
            }

            let triggerEl = document.getElementById(this.$id(newActiveEntry + "-tab"));
            let tab = new Tab(triggerEl);
            tab.show();
        },

        openSettings() : void {
            this.$router.push({ path : "/" + MainMenuSection.USER + "/einstellungen" });
        },

        openAccessEdit() : void {
            this.$router.push({ path : "/" + MainMenuSection.USER + "/profil/zugang" });
        },

        openPersonalDataEdit() : void {
            this.$router.push({ path : "/" + MainMenuSection.USER + "/profil/daten" });
        },
/*
        openAbout() : void {
            this.$router.push({ path : "/" + MainMenuSection.HELP + "/about" });
        },
*/
        logout() : void {
            this.ctx.userLoginService.logout(this.ctx.getTabSessionId()).then(() => {
                this.$router.go(0);
                log.debug("Logout successful.");
            }, err => {
                ErrorHelper.processError(this.$t, this.$d, err);
            });
        }
    },

    // See: https://v3.vuejs.org/api/options-data.html#watch
    //      https://v3.vuejs.org/api/instance-methods.html#watch
    watch : {
        activeEntry(newActiveEntry : MainMenuSection, oldActiveEntry : MainMenuSection) {
            this.processActiveEntryChanged(newActiveEntry, oldActiveEntry);
        }
    },

/*
    setup(props) {
        watch(toRef(props, "activeEntry"), (activeEntry) => {
            let selection : string = "#" + activeEntry + "-tab";
            let triggerEl = document.querySelector(selection);
            let tab = new Tab(triggerEl);
            tab.show();
        });
    }
*/
});
