import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["disabled", "data-bs-target", "aria-controls"]
const _hoisted_2 = { class: "text-expanded" }
const _hoisted_3 = { class: "text-collapsed" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "col-lg-4" }
const _hoisted_6 = { class: "row g-3" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-sm-9" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-sm-9" }
const _hoisted_11 = { class: "col-lg-4" }
const _hoisted_12 = { class: "row g-3" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-sm-9" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-sm-9" }
const _hoisted_17 = { class: "col-lg-4" }
const _hoisted_18 = { class: "row g-3" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-sm-9" }
const _hoisted_21 = { class: "col-12" }
const _hoisted_22 = { class: "row g-3" }
const _hoisted_23 = { class: "col-sm-auto mr-auto" }
const _hoisted_24 = { class: "row g-3" }
const _hoisted_25 = { class: "col-auto" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = { class: "col-auto" }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = { class: "col-sm-auto ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      class: "btn cdes-btn-collapse text-start align-self-start ps-2",
      disabled: this.disabled,
      "data-bs-toggle": "collapse",
      "data-bs-target": '#' + _ctx.$id('collapse'),
      "aria-expanded": "true",
      "aria-controls": _ctx.$id('collapse')
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('general.button.collapseSearch')), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.button.openSearch')), 1)
    ], 8, _hoisted_1),
    _createElementVNode("div", {
      class: "collapse show ms-4 me-3",
      id: _ctx.$id('collapse')
    }, [
      _createElementVNode("form", {
        class: "row g-3 mb-3",
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.doSearch && _ctx.doSearch(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Label, {
                  for: _ctx.$id('organisation'),
                  class: "col-sm-3 col-form-label text-sm-end"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.organisation')), 1)
                  ]),
                  _: 1
                }, 8, ["for"]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Select, {
                    mode: "flex",
                    disabled: this.disabled,
                    defaultLabel: _ctx.$t('general.all'),
                    id: _ctx.$id('organisation'),
                    selectClass: "form-select",
                    options: _ctx.organisationsOptions,
                    modelValue: _ctx.organisationId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.organisationId) = $event)),
                    optional: "",
                    sort: true
                  }, null, 8, ["disabled", "defaultLabel", "id", "options", "modelValue"])
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Label, {
                  for: _ctx.$id('role'),
                  class: "col-sm-3 col-form-label text-sm-end"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.role')), 1)
                  ]),
                  _: 1
                }, 8, ["for"]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_Select, {
                    mode: "flex",
                    disabled: this.disabled,
                    defaultLabel: _ctx.$t('general.all'),
                    id: _ctx.$id('role'),
                    selectClass: "form-select",
                    options: _ctx.rolesOptions,
                    modelValue: _ctx.roleId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.roleId) = $event)),
                    optional: "",
                    sort: ""
                  }, null, 8, ["disabled", "defaultLabel", "id", "options", "modelValue"])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_Label, {
                  for: _ctx.$id('mainParticipant'),
                  class: "col-sm-3 col-form-label text-sm-end"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.mainParticipant')), 1)
                  ]),
                  _: 1
                }, 8, ["for"]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_Select, {
                    mode: "flex",
                    id: _ctx.$id('mainParticipant'),
                    disabled: this.disabled,
                    defaultLabel: _ctx.$t('general.all'),
                    selectClass: "form-select",
                    options: _ctx.mainParticipantsOptions,
                    modelValue: _ctx.mainParticipantId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mainParticipantId) = $event)),
                    optional: "",
                    sort: ""
                  }, null, 8, ["id", "disabled", "defaultLabel", "options", "modelValue"])
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_Label, {
                  for: _ctx.$id('deputy'),
                  class: "col-sm-3 col-form-label text-sm-end"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.deputy')), 1)
                  ]),
                  _: 1
                }, 8, ["for"]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_Select, {
                    mode: "flex",
                    disabled: this.disabled,
                    defaultLabel: _ctx.$t('general.all'),
                    id: _ctx.$id('deputy'),
                    selectClass: "form-select",
                    options: _ctx.deputiesOptions,
                    modelValue: _ctx.deputyId,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.deputyId) = $event)),
                    optional: "",
                    sort: ""
                  }, null, 8, ["disabled", "defaultLabel", "id", "options", "modelValue"])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_19, [
                (!this.buek)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_Label, {
                        for: _ctx.$id('subProject'),
                        class: "col-sm-3 col-form-label text-sm-end"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('general.subProject')), 1)
                        ]),
                        _: 1
                      }, 8, ["for"]),
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_Select, {
                          mode: "flex",
                          disabled: this.disabled,
                          defaultLabel: _ctx.$t('general.all'),
                          id: _ctx.$id('subProject'),
                          selectClass: "form-select",
                          options: _ctx.subProjectsOptions,
                          modelValue: _ctx.subProjectId,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.subProjectId) = $event)),
                          optional: "",
                          sort: ""
                        }, null, 8, ["disabled", "defaultLabel", "id", "options", "modelValue"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("button", {
                    type: "submit",
                    class: "btn btn-secondary",
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (this.clearSearchFields && this.clearSearchFields(...args))),
                    disabled: this.disabled
                  }, _toDisplayString(_ctx.$t("general.button.resetSearchCaption")), 9, _hoisted_26)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("button", {
                    type: "submit",
                    class: "btn btn-primary me-2",
                    disabled: this.disabled
                  }, _toDisplayString(_ctx.$t("general.button.searchCaption")), 9, _hoisted_28)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_29, [
              _renderSlot(_ctx.$slots, "extraButtons")
            ])
          ])
        ])
      ], 32)
    ], 8, _hoisted_4)
  ]))
}